import { Notifier } from '@iolabs/notifier';
import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useKeycloak } from '@react-keycloak/web';
import { SnackbarProvider } from 'notistack';
import React, { PropsWithChildren } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    success: {
      backgroundColor: theme.palette.secondary.main,
    },
    error: {
      backgroundColor: theme.palette.error.main,
    },
    warning: {
      backgroundColor: theme.palette.error.main,
    },
    info: {
      backgroundColor: theme.palette.primary.main,
    },
  }),
);

interface IProps {
  globalCss: React.ReactNode;
}

const App: React.FC<PropsWithChildren<IProps>> = (props: PropsWithChildren<IProps>) => {
  const classes = useStyles();
  const { keycloak } = useKeycloak();
  const { children, globalCss } = props;

  return (
    <SnackbarProvider
      maxSnack={3}
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info,
      }}
    >
      {keycloak.authenticated ? (
        <>
          {globalCss}
          {children}
          <Notifier />
        </>
      ) : (
        <></>
      )}
    </SnackbarProvider>
  );
};

export default App;
