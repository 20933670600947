import { defineMessages } from 'react-intl';

export default defineMessages({
    headerTitle: {
        id: 'pages.notFoundPage.headerTitle',
        defaultMessage: 'Page not found',
    },
    notFound: {
        id: 'pages.notFoundPage.notFound',
        defaultMessage: 'Page not found',
    },
    homepage: {
        id: 'pages.notFoundPage.homepage',
        defaultMessage: 'Back to homepage',
    },
});
