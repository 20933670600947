import { useLanguageProvider } from '@iolabs/language-provider';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, MenuItem, Popover, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';

const useLanguageSwitcherStyles = makeStyles((theme: Theme) =>
    createStyles({
        switcherButton: {
            marginRight: theme.spacing(5),
            fontSize: theme.typography.pxToRem(16),
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
        },
        switcherButtonArrow: {
            fontSize: theme.typography.pxToRem(14),
            marginLeft: '4px',
        },
    }),
);

const LanguageSwitcher = () => {
    const classes = useLanguageSwitcherStyles();

    const { languages, currentLanguage, setCurrentLanguage } = useLanguageProvider();

    const handleLanguageToggle = language => {
        setCurrentLanguage(language);
        setAnchorEl(null);
    };

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'language-popover' : undefined;

    return (
        <>
            <Box aria-describedby={id} onClick={handleOpen} className={classes.switcherButton}>
                <Box>{currentLanguage?.toUpperCase()}</Box>
                <ExpandMoreIcon className={classes.switcherButtonArrow} />
            </Box>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {languages?.map((language, index) => {
                    return (
                        <MenuItem
                            onClick={() => handleLanguageToggle(language)}
                            key={index}
                            disabled={currentLanguage === language}
                        >
                            <Typography variant="inherit">{language?.toUpperCase()}</Typography>
                        </MenuItem>
                    );
                })}
            </Popover>
        </>
    );
};

export default LanguageSwitcher;
