import { Box, Button, DialogActions, DialogContent, InputAdornment } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import clsx from 'clsx';
import { TextField } from 'mui-rff';
import React, { useCallback, useContext, useState } from 'react';
import { Form } from 'react-final-form';
import { useIntl } from 'react-intl';

import { DialogContext } from '../../../dialogs/DialogProvider/DialogProvider';
import { updateShowcaseItem } from '../../../packages/Api/data/modelingShowcase/client';
import { IModelingShowcaseItem } from '../../../packages/Api/data/modelingShowcase/types';
import { IModelingItemProgress } from '../../ModelingProgress/ModelingProgressItem';
import { DF } from '../DialogFactory';
import messages from './messages';
import useStyles from './styles';
import {
    IUpdateModelingShowcaseItemFormErrors,
    IUpdateModelingShowcaseItemFormValues,
} from './type';

interface IUpdateModelingShowcaseItemProps {
    item: IModelingItemProgress;
    onSaved?: (item: IModelingShowcaseItem) => void;
}

const UpdateModelingShowcaseItem: React.FC<IUpdateModelingShowcaseItemProps> = ({
    item,
    onSaved,
}) => {
    const classes = useStyles();
    const { keycloak, initialized: keycloakInitialized } = useKeycloak();
    const { toggleDialog } = useContext(DialogContext);

    const [saving, setSaving] = useState<boolean>(false);

    const [initialFormValues] = useState<IUpdateModelingShowcaseItemFormValues | null>({
        name: item.title,
        details: item.description,
        progress: item.progress,
        modelLink: item.modelLink,
    });

    // translations
    const intl = useIntl();
    const transValidationDetail = intl.formatMessage({ ...messages.validationDetails });
    const transBtnSend = intl.formatMessage({ ...messages.btnSend });

    const validate = (
        values: IUpdateModelingShowcaseItemFormValues,
    ): IUpdateModelingShowcaseItemFormErrors => {
        const errors: IUpdateModelingShowcaseItemFormErrors = {};
        if (!values?.details) {
            (errors as IUpdateModelingShowcaseItemFormErrors).details = transValidationDetail;
        }
        return errors;
    };

    const onSubmit = useCallback(
        async (values: IUpdateModelingShowcaseItemFormValues) => {
            setSaving(true);
            updateShowcaseItem(
                keycloak?.token as string,
                {
                    modelingShowcaseItemID: item.key,
                    details: values.details,
                    name: values.name,
                    itemProgress: values.progress,
                    modelLink: values.modelLink,
                } as IModelingShowcaseItem,
            )
                .then(response => {
                    toggleDialog(DF.UPDATE_MODELING_SHOWCASE_ITEM, false);
                    if (onSaved) {
                        onSaved(response.modelingShowcaseItem);
                    }
                })
                .catch(error => {
                    console.error(error);
                    alert(error);
                })
                .finally(() => {
                    setSaving(false);
                });
        },
        [keycloakInitialized, toggleDialog],
    );

    return (
        <Form onSubmit={onSubmit} initialValues={initialFormValues} validate={validate}>
            {({ handleSubmit, submitting, pristine }) => (
                <form onSubmit={handleSubmit}>
                    <DialogContent dividers>
                        <Box className={classes.formContent}>
                            <TextField
                                required
                                name="name"
                                label={'Name'}
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                required
                                name="progress"
                                label={'Progress'}
                                type={'number'}
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                className={classes.textField}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                name="modelLink"
                                label={'Model link'}
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                required
                                multiline
                                rows={8}
                                name="details"
                                label={'Details'}
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                className={clsx(classes.textField, classes.pre)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Box display="flex" justifyContent="center" width="100%">
                            <Button
                                type="submit"
                                variant="outlined"
                                size="small"
                                disabled={submitting || pristine || saving}
                                className={classes.button}
                            >
                                {transBtnSend}
                            </Button>
                        </Box>
                    </DialogActions>
                </form>
            )}
        </Form>
    );
};

export default UpdateModelingShowcaseItem;
