import { Notifier } from '@iolabs/notifier';
import { IonBackdrop, IonSpinner } from '@ionic/react';
import { Theme, useTheme } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import { SnackbarProvider } from 'notistack';
import React, { PropsWithChildren } from 'react';
import { Route } from 'react-router';

import MinimalisticPage from '../../pages/MinimalisticPage/MinimalisticPage';
import PageRouter from '../../pages/PageRouter';
import { template } from '../../theme/Theme';
import DialogFactory from '../DialogFactory/DialogFactory';
import FloatingActionButton from '../FloatingActionButton/FloatingActionButton';
import FooterProvider from '../FooterProvider/FooterProvider';
import Layout from '../Layout/Layout';
import useStyles from './styles';

const App: React.FC<PropsWithChildren> = () => {
    const classes = useStyles();
    const theme = useTheme<Theme>();
    const { initialized: keycloakInitialized } = useKeycloak();

    return (
        <>
            {!keycloakInitialized ? (
                <div className={classes.spinnerBox}>
                    <IonBackdrop tappable={false} />
                    <IonSpinner className={classes.spinnerItem} />
                </div>
            ) : (
                <SnackbarProvider
                    maxSnack={3}
                    dense
                    preventDuplicate
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    classes={{
                        variantSuccess: classes.success,
                        variantError: classes.error,
                        variantWarning: classes.warning,
                        variantInfo: classes.info,
                    }}
                >
                    <Route path={'/public'} component={MinimalisticPage} />
                    <Route
                        render={() => {
                            return (
                                <>
                                    <FooterProvider>
                                        <Layout
                                            drawer={{
                                                disabled: true,
                                            }}
                                            stylesProps={{
                                                headerBarColor: template.headerBarColor,
                                                headerBarBackground: template.headerBarBackground,
                                                drawerHeaderBackground: theme.palette.primary.main,
                                                drawerBackground: theme.palette.grey['50'],
                                                footerBarColor: theme.palette.common.black,
                                                footerBarBackground: theme.palette.common.white,
                                            }}
                                        >
                                            <PageRouter />
                                        </Layout>
                                    </FooterProvider>
                                    <DialogFactory />
                                    <FloatingActionButton />
                                </>
                            );
                        }}
                    />

                    <Notifier />
                </SnackbarProvider>
            )}
        </>
    );
};

export default App;
