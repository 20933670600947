import Widget from '@arcgis/core/widgets/Widget';
import { Close } from '@mui/icons-material';
import {
    Box,
    Button,
    ButtonGroup,
    Card,
    CardContent,
    ClickAwayListener,
    Icon,
    IconButton,
    Typography,
} from '@mui/material';
import clsx from 'clsx';
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';

import { IToolDef, toolsEquals } from './EsriViewer';
import useStyles from './styles';

interface IReactToolsProps {
    tools: IToolDef[];
}

const ReactTools: React.FC<IReactToolsProps> = ({ tools }) => {
    const classes = useStyles();
    const [activeTool, setActiveTool] = useState<IToolDef>();

    const activatedTools = useRef<string[]>([]);

    const handleClickTool = useCallback(
        tool => {
            setActiveTool(toolsEquals(tool, activeTool) ? undefined : tool);
        },
        [activeTool],
    );

    const activateTool = (tool: IToolDef) => (el: HTMLElement) => {
        if (tool.tool instanceof Widget && !activatedTools.current.includes(tool.code)) {
            tool.tool.container = el as HTMLElement;
            activatedTools.current.push(tool.code);
        }
    };

    return (
        <Box className={classes.reactToolsOverlay}>
            <Box className={classes.reactToolsCards}>
                {tools.map(tool => (
                    <Card
                        key={`tool-${tool.code}`}
                        sx={{ maxWidth: 450 }}
                        className={clsx(classes.reactToolsDialog, {
                            [classes.reactToolsActiveDialog]: toolsEquals(tool, activeTool),
                        })}
                    >
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                {tool.name}
                                <IconButton
                                    className={classes.closeIcon}
                                    onClick={() => setActiveTool(undefined)}
                                >
                                    <Close />
                                </IconButton>
                            </Typography>
                            <Box
                                className={classes.reactToolsDialogContent}
                                ref={activateTool(tool)}
                            >
                                {React.isValidElement(tool.tool) && tool.tool}
                            </Box>
                        </CardContent>
                    </Card>
                ))}
            </Box>
            <Box className={classes.reactToolsButtons}>
                {tools.map(tool => (
                    <Button
                        key={`tool-${tool.code}`}
                        size={'small'}
                        onClick={() => handleClickTool(tool)}
                        className={toolsEquals(tool, activeTool) ? classes.reactToolsActive : ''}
                    >
                        <Icon>{tool.icon}</Icon>
                    </Button>
                ))}
            </Box>
        </Box>
    );
};
export default ReactTools;
