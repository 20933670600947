import { AnyAction } from 'redux';

import { ActionTypes } from './action';

export interface IKeycloakModuleState {
    keycloakState: IKeycloakState;
}

export interface IKeycloakState {
    idToken: string;
    refreshToken: string;
    token: string;
    event: unknown | null;
}

const initialState: Readonly<IKeycloakState> = {
    idToken: '',
    refreshToken: '',
    token: '',
    event: null,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export function reducer(state: IKeycloakState = initialState, action: AnyAction): IKeycloakState {
    switch (action.type) {
        case ActionTypes.OnEvent: {
            const { event } = action.payload;
            return { ...state, event };
        }

        case ActionTypes.OnTokens: {
            const { idToken, refreshToken, token } = action.payload;
            return { ...state, idToken, refreshToken, token };
        }

        default:
            return state;
    }
}
