import { ApiClient } from '@iolabs/api-client';
import { onStart } from '@iolabs/app';
import { AnyAction } from 'redux';

import { ActionTypes } from './action';
import { selectEvent, selectToken } from './selector';

const onKeycloackEventMiddleware =
    ({ dispatch, getState }) =>
    next =>
    (action: AnyAction) => {
        const resolve = next(action);

        if (action.type === ActionTypes.OnEvent) {
            const event = selectEvent(getState());
            if (event === 'onReady') {
                return dispatch(onStart());
            }
        }
        if (action.type === ActionTypes.OnTokens) {
            const token = selectToken(getState());
            ApiClient.setAuthorizationToken(token);
        }

        return resolve;
    };

export default [onKeycloackEventMiddleware];
