import { useContext } from 'react';

import { FooterContext, IFooterContext } from './FooterProvider';

const useFooterContent = () => {
    const { footerContent, setFooterContent } = useContext<IFooterContext>(FooterContext);

    return {
        footerContent,
        setFooterContent,
    };
};

export default useFooterContent;
