import { AppModule } from '@iolabs/app';
import { NotifierModule } from '@iolabs/notifier';
import React, { ReactNode } from 'react';
import { Provider } from 'react-redux';
import { compose } from 'redux';
import { createStore, IModuleStore } from 'redux-dynamic-modules';
import { getThunkExtension } from 'redux-dynamic-modules-thunk';

import config from '../config/config';
import { FeedbackModule } from './feedback/module';
import { KeycloakModule } from './keyclock/module';

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

// add Redux debugging tool as Chrome extension when development environment is active
const composeEnhancers =
    config.envType === 'development'
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
        : compose;

const store: IModuleStore<unknown> = createStore(
    {
        initialState: {},
        advancedComposeEnhancers: composeEnhancers,
        extensions: [getThunkExtension()],
    },
    AppModule,
    NotifierModule,
    KeycloakModule,
    FeedbackModule,
);

type Props = {
    children: ReactNode;
};

export function StoreProvider(props: Props): JSX.Element {
    const { children } = props;
    return <Provider store={store}>{children}</Provider>;
}
