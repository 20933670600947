import {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';

import { createClient } from './clientFactory';
import { config as clientConfig } from './config';
import {handleHttpErrors, ICustomError} from './errorHandler';

let authorizationToken: string | null = null;
/**
 * ApiClient class used as for http requests
 * @class
 */
export default class ApiClient {
  private client: AxiosInstance;

  constructor(basePath = '', baseUrl: string = clientConfig.baseUrl) {
    this.client = createClient({
      baseURL: `${baseUrl}${basePath}`,
    });
  }

  private request<T>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    if (authorizationToken !== null) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${authorizationToken}`,
      };
    }

    return this.client
      .request({
        ...config,
      })
      .then((response: AxiosResponse<T>) => Promise.resolve(response))
      .catch((error: AxiosError<ICustomError>) => handleHttpErrors(error));
  }

  /**
   * Set token used for api calls
   * @param {string} token Authorization token as a string
   */
  public static setAuthorizationToken(token: string): void {
    authorizationToken = token;
  }

  /**
   * Perform GET request
   * @param {string} url Target URL
   * @param {AxiosRequestConfig} config Request configuration
   */
  public get<T>(url: string, config: AxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
    return this.request<T>({ ...config, url, method: 'GET' });
  }

  /**
   * Perform DELETE request
   * @param {string} url Target URL
   * @param {AxiosRequestConfig} config Request configuration
   */
  public delete<T>(url: string, config: AxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
    return this.request<T>({ ...config, url, method: 'DELETE' });
  }

  /**
   * Perform HEAD request
   * @param {string} url Target URL
   * @param {AxiosRequestConfig} config Request configuration
   */
  public head<T>(url: string, config: AxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
    return this.request<T>({ ...config, url, method: 'HEAD' });
  }

  /**
   * Perform POST request
   * @param {string} url Target URL
   * @param {object} data Data to send
   * @param {AxiosRequestConfig} config Request configuration
   */
  public post<T>(url: string, data: object = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
    return this.request<T>({ ...config, url, method: 'POST', data });
  }

  /**
   * Perform PUT request
   * @param {string} url Target URL
   * @param {object} data Data to send
   * @param {AxiosRequestConfig} config Request configuration
   */
  public put<T>(url: string, data: object = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
    return this.request<T>({ ...config, url, method: 'PUT', data });
  }

  /**
   * Perform PATCH request
   * @param {string} url Target URL
   * @param {object} data Data to send
   * @param {AxiosRequestConfig} config Request configuration
   */
  public patch<T>(url: string, data: object = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
    return this.request<T>({ ...config, url, method: 'PATCH', data });
  }
}
