import { AxiosError } from 'axios';

import { createError } from './errorFactory';
import ForbiddenHttpError from './errors/ForbiddenHttpError';
import NetworkError from './errors/NetworkError';
import UnauthorizedHttpError from './errors/UnauthorizedHttpError';

export interface ICustomError {
  errorId: number;
}

export const handleHttpErrors = (error: AxiosError<ICustomError>) => {
  if (error.response) {
    if (error.response.status === 401) {
      throw new UnauthorizedHttpError();
    } else if (error.response.status === 403) {
      throw new ForbiddenHttpError();
    } else if (error.response.data.errorId) {
      throw createError(error.response.data.errorId, error.response.data);
    } else {
      throw error;
    }
  } else if (error.request) {
    throw new NetworkError();
  } else {
    throw error;
  }
};
