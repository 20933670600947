import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        itemBox: {
            zIndex: 100,
            position: 'absolute',
            overflow: 'visible',
            transform: 'translate(-50%, -50%)',
            lineHeight: '0',
        },
        root: {
            width: '100%',
            height: '100%',
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 1,
            pointerEvents: 'none',
        },
        active: {
            display: 'block',
        },
        viewerWrapper: {
            position: 'relative',
            zIndex: 0,
            height: '100%',
            width: '100%',
        },
    }),
);

export default useStyles;
