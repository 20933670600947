import { Box, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';

import { template } from '../../theme/Theme';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'relative',
            padding: theme.spacing(1),
            height: `calc(100% - 60px - ${theme.spacing(1)})`,
            background: template.bodyBgColor,
            [theme.breakpoints.up('md')]: {
                height: `calc(100% - 30px - ${theme.spacing(1)})`,
            },
        },
        noPadding: {
            padding: 0,
            height: `calc(100% - 60px + ${theme.spacing(1)})`,
            [theme.breakpoints.up('md')]: {
                height: `calc(100% - 30px + ${theme.spacing(1)})`,
            },
        },
    }),
);

interface IPageProps extends React.HTMLAttributes<HTMLElement> {
    noPadding?: boolean;
}

const Page: React.FC<IPageProps> = (props: IPageProps) => {
    const { children, noPadding } = props;

    const classes = useStyles();

    return <Box className={clsx(classes.root, { [classes.noPadding]: noPadding })}>{children}</Box>;
};

export default Page;
