import { Box, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

import CustomEsriViewer from '../../components/EsriViewer/CustomEsriViewer';
import { useHeaderStyles } from '../../components/Header/Header';
import useHeaderContent from '../../components/HeaderProvider/useHeaderContent';
import config from '../../config/config';
import Page from '../Page/Page';
import messages from './messages';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dashboardBox: {
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            flex: 1,
            height: '100%',
            overflow: 'auto',
            [theme.breakpoints.up('md')]: {
                flexFlow: 'row wrap',
                justifyContent: 'center',
                alignContent: 'center',
            },
        },
        dashboardItem: {
            height: '300px',
            width: '200px',
            margin: theme.spacing(1),
            display: 'flex',
            flexFlow: 'column nowrap',
            justifyContent: 'space-between',
            '&:last-child': {
                marginBottom: theme.spacing(4),
            },
            [theme.breakpoints.up('md')]: {
                height: '400px',
                width: '300px',
                margin: theme.spacing(2),
                '&:last-child': {
                    marginBottom: theme.spacing(2),
                },
            },
        },
    }),
);

const EsriPage: React.FC = () => {
    const classes = useStyles();
    const headerClasses = useHeaderStyles();
    const { setHeaderContent } = useHeaderContent();

    // translations
    const intl = useIntl();
    const transHeaderTitle = intl.formatMessage({ ...messages.headerTitle });

    useEffect(() => {
        setHeaderContent(
            <Typography variant="h1" component="h1" className={headerClasses.heading}>
                {transHeaderTitle}
            </Typography>,
        );
    }, []);

    return (
        <Page noPadding={true}>
            <Helmet>
                <title>{`${config?.template?.title} - Web Services`}</title>
            </Helmet>
            <Box className={classes.dashboardBox}>
                <CustomEsriViewer />
            </Box>
        </Page>
    );
};

export default EsriPage;
