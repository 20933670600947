import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import '../../theme/variables.css';

import { LanguageProvider } from '@iolabs/language-provider';
import { GlobalDialogProvider as WipDialogProvider } from '@iolabs/wip-dialog';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import React from 'react';
import { Route } from 'react-router-dom';

import DialogProvider from '../../dialogs/DialogProvider/DialogProvider';
import SecurityProvider from '../../redux/keyclock/provider';
import { StoreProvider } from '../../redux/provider';
import theme from '../../theme/Theme';
import de from './../../translations/de.json';
import en from './../../translations/en.json';
import fr from './../../translations/fr.json';
import App from './App';
import { PrivateRoute } from './PrivateRoute';
import PublicApp from './PublicApp';

const AppWrapper: React.FC = () => {
    const renderApp = () => {
        return (
            <SecurityProvider>
                <WipDialogProvider>
                    <DialogProvider>
                        <IonApp>
                            <IonReactRouter>
                                <IonRouterOutlet animated={false}>
                                    <Route path="/public" component={PublicApp} />
                                    <PrivateRoute component={App} />
                                </IonRouterOutlet>
                            </IonReactRouter>
                        </IonApp>
                    </DialogProvider>
                </WipDialogProvider>
            </SecurityProvider>
        );
    };

    return (
        <StoreProvider>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <LanguageProvider
                        defaultLocale="de"
                        messages={{
                            de,
                            en,
                            fr,
                        }}
                    >
                        {renderApp()}
                    </LanguageProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </StoreProvider>
    );
};

export default AppWrapper;
