import { IModule } from 'redux-dynamic-modules';

import middlewares from './middleware';
import { INotifierModuleState, reducer } from './reducer';

export const NotifierModule: IModule<INotifierModuleState> = {
  id: 'notifier-module',
  reducerMap: {
    notifierState: reducer,
  },
  middlewares: middlewares,
};
