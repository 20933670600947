import { createAction, DispatchAction } from '@iolabs/redux-utils';

export enum ActionTypes {
    OnEvent = 'keycloak/ON_EVENT',
    OnTokens = 'keycloak/ON_TOKENS',
}

export type OnEventOptions = {
    event: unknown;
};

export type OnTokensOptions = {
    idToken?: string;
    refreshToken?: string;
    token?: string;
};

export const Actions = {
    onEvent: (options: OnEventOptions) => createAction(ActionTypes.OnEvent, options),
    onTokens: (options: OnTokensOptions) => createAction(ActionTypes.OnTokens, options),
};

export function onEvent(options: OnEventOptions): DispatchAction {
    return async dispatch => {
        dispatch(Actions.onEvent(options));
    };
}

export function onTokens(options: OnTokensOptions): DispatchAction {
    return async dispatch => {
        dispatch(Actions.onTokens(options));
    };
}
