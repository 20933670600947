import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogPaper: {
            minHeight: '80vh',
            maxHeight: '80vh',
            height: '100%',
            minWidth: '90vw',
            maxWidth: '90vw',
            width: '100%',
        },
        dialogContent: {
            padding: 0,
            width: '100%',
            height: '100%',
            display: 'block',
            position: 'relative',
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
        },
    }),
);

export default useStyles;
