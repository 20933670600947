import { IonRouterOutlet } from '@ionic/react';
import { AppBar, Box, Theme, Typography, useTheme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import { Route } from 'react-router';

import Footer from '../../components/Footer/Footer';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            height: '100%',
        },
        content: {
            height: 'calc(100% - 20px)',
            position: 'relative',
        },
        footerBar: {
            height: '20px',
            background: theme.palette.common.black,
            color: theme.palette.common.white,
            zIndex: theme.zIndex.drawer - 1,
            top: 'auto',
            bottom: 0,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
    }),
);
const MinimalisticPage: React.FC = () => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <Box className={classes.root}>
            <Box className={classes.content}>
                <IonRouterOutlet>
                    <Route
                        render={() => {
                            return (
                                <Box>
                                    <Typography>default</Typography>
                                </Box>
                            );
                        }}
                    />
                </IonRouterOutlet>
            </Box>

            <AppBar component="footer" elevation={0} className={classes.footerBar}>
                <Footer
                    hideLasUpdate={true}
                    stylesProps={{
                        // footerHeight: stylesProps?.footerHeight,
                        footerBarColor: theme.palette.common.black,
                        footerBarBackground: theme.palette.common.white,
                    }}
                />
            </AppBar>
        </Box>
    );
};

export default MinimalisticPage;
