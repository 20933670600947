import { IModule } from 'redux-dynamic-modules';

import middlewares from './middleware';
import { IAppModuleState, reducer } from './reducer';

export const AppModule: IModule<IAppModuleState> = {
  id: 'app-module',
  reducerMap: {
    appState: reducer,
  },
  middlewares: middlewares,
};
