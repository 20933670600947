import React, { createContext, Dispatch, PropsWithChildren, SetStateAction, useState } from 'react';

export interface IHeaderContext {
    headerContent: React.ReactNode;
    setHeaderContent: Dispatch<SetStateAction<React.ReactNode | null>>;
}

export const HeaderContext = createContext<IHeaderContext>({
    headerContent: null,
    setHeaderContent: () => {},
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IHeaderProviderProps {}

const HeaderProvider: React.FC<PropsWithChildren<IHeaderProviderProps>> = ({ children }) => {
    const [headerContent, setHeaderContent] = useState<React.ReactNode>();

    const value = { headerContent, setHeaderContent };

    return (
        <HeaderContext.Provider value={value}>
            {children}
            {headerContent ? headerContent : null}
        </HeaderContext.Provider>
    );
};

export default HeaderProvider;
