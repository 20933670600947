import SceneView from '@arcgis/core/views/SceneView';
import { CardContent, CardMedia, MenuItem, MenuList, Typography } from '@mui/material';
import React, { useState } from 'react';

import useStyles from './styles';

export interface IFeatureFilter {
    code: string;
    name: string;
    description: string;
    filter: (view: SceneView) => void;
    thumbnail?: string;
}

interface IFeatureFilterProps {
    view: SceneView;
    filters: IFeatureFilter[];
}

const FeatureFilter: React.FC<IFeatureFilterProps> = ({ view, filters }) => {
    const classes = useStyles();

    const [activeFilter, setActiveFilter] = useState<IFeatureFilter>(filters[0]); // todo better listen on view filter

    const handleFilter = filter => () => {
        setActiveFilter(filter);
        filter.filter(view);
    };

    return (
        <>
            <MenuList>
                {filters.map(filter => (
                    <MenuItem
                        key={`filter-${filter.code}`}
                        onClick={handleFilter(filter)}
                        selected={filter === activeFilter}
                        className={classes.filterCard}
                    >
                        {/*<Card className={classes.filterCard}>*/}
                        <CardMedia
                            component="img"
                            sx={{ width: 90 }}
                            image={filter.thumbnail}
                            alt={filter.name}
                        />
                        <CardContent>
                            <Typography component="div" variant="h5">
                                {filter.name}
                            </Typography>
                            <Typography variant="subtitle1" color="text.secondary" component="div">
                                {filter.description}
                            </Typography>
                        </CardContent>
                        {/*</Card>*/}
                        {/*<ListItemAvatar>*/}
                        {/*    <Avatar {...stringAvatar(filter.code)} />*/}
                        {/*</ListItemAvatar>*/}
                        {/*<ListItemText primary={filter.code.toUpperCase()} secondary={filter.name} />*/}
                    </MenuItem>
                ))}
            </MenuList>
        </>
    );
};
export default FeatureFilter;
