import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        spinnerBox: {
            display: 'flex',
            flexFlow: 'row nowrap',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
        },
        spinnerItem: {
            width: '60px',
            height: '60px',
            color: theme.palette.common.black,
        },
        success: {
            color: `${theme.palette.text.primary} !important`,
            backgroundColor: `${theme.palette.success.main} !important`,
        },
        error: {
            backgroundColor: `${theme.palette.error.main} !important`,
        },
        warning: {
            backgroundColor: `${theme.palette.warning.main} !important`,
        },
        info: {
            backgroundColor: `${theme.palette.info.main} !important`,
        },
    }),
);

export default useStyles;
