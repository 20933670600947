import Basemap from '@arcgis/core/Basemap';
import esriConfig from '@arcgis/core/config';
import Handles from '@arcgis/core/core/Handles';
import Point from '@arcgis/core/geometry/Point';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import SceneView from '@arcgis/core/views/SceneView';
import WebScene from '@arcgis/core/WebScene';
import Widget from '@arcgis/core/widgets/Widget';
import React, { ReactElement, useEffect, useRef } from 'react';

import useStyles from './styles';
import ViewerTools, { ViewerToolsRenderer } from './ViewerTools';

interface IEsriViewerProps {
    webSceneId: string;
    viewLoaded?: (view: SceneView) => void;
    onClick?: (point: ClickedPoint) => void;
    highlights?: Highlights;
    disableAutoPopup?: boolean;
    toolsGetter?: (view: SceneView) => IToolDef[];
    baseMap?: Basemap;
}

export interface IToolDef {
    name: string;
    code: string;
    icon?: string;
    tool: Node | string | Widget | ReactElement;
}

export type Highlights = { [key: number]: string[] };

export interface ScreenPoint {
    x: number;
    y: number;
}

export interface ClickedPoint {
    screen: ScreenPoint;
    world: Point;
    mouseButton: number;
    object?: any;
}

export const toolsEquals = (tool1?: IToolDef, tool2?: IToolDef): boolean => {
    return tool1?.code === tool2?.code;
};

const initializeEsri = (container: HTMLDivElement, webSceneId: string): Promise<SceneView> => {
    return new Promise<SceneView>(resolve => {
        //
        // const layer = new FeatureLayer({
        //     portalItem: {
        //         id: 'bb3aa15614884178857fda4d88c297a2',
        //     },
        //     outFields: ['OBJECTID_1'],
        // });

        /**
         * Initialize application
         */
        const webmap = new WebScene({
            portalItem: {
                id: webSceneId,
            },
        });

        const view = new SceneView({
            container: container,
            map: webmap,
        });

        // Promise.all([webmap, view]).then(() => {
        //     resolve(view);
        // });
        webmap.when(() => {
            resolve(view);
        });

        // view.when(() => {
        //     resolve(view);
        // });
    });
};

const EsriViewer: React.FC<IEsriViewerProps> = ({
    webSceneId,
    viewLoaded,
    onClick,
    disableAutoPopup,
    highlights,
    toolsGetter,
    baseMap,
}) => {
    const classes = useStyles();
    const mapDiv = useRef(null);

    const [view, setView] = React.useState<SceneView>();
    const [currentHighlights, setCurrentHighlights] = React.useState<Handles[]>();

    useEffect(() => {
        if (mapDiv.current) {
            initializeEsri(mapDiv.current, webSceneId).then(loadedView => {
                handleSetView(loadedView);
                if (baseMap) {
                    // temporary must be set manually
                    // https://community.esri.com/t5/arcgis-api-for-javascript-questions/changing-basemaps-using-activebasemap-lt-basemapid/td-p/1161336
                    // v4.24 has some other problem with imageslice
                    loadedView.map.basemap = baseMap;
                }
            });
        }
    }, []);

    useEffect(() => {
        if (view) {
            view.popup.autoOpenEnabled = !disableAutoPopup;
            view.on('click', event => {
                const point: ClickedPoint = {
                    screen: {
                        x: event.x,
                        y: event.y,
                    },
                    world: event.mapPoint,
                    mouseButton: event.button,
                };

                view.hitTest(point.screen).then(response => {
                    if (response.results.length) {
                        const object = response.results[0];
                        point.object = object;
                    }
                    if (onClick) {
                        onClick(point);
                    }
                });
            });
        }
    }, [view]);

    useEffect(() => {
        const handles: Handles[] = [];
        if (currentHighlights) {
            currentHighlights.forEach(handle => {
                handle.remove();
            });
        }
        if (highlights) {
            view?.allLayerViews.forEach(lw => {
                lw.when(layerView => {
                    if (layerView.layer.id) {
                        highlights[layerView.layer.id]?.forEach(objectId => {
                            handles.push(layerView.highlight(objectId));
                        });
                    }
                });
            });
        }
        setCurrentHighlights(handles);
    }, [highlights]);

    const handleSetView = loadedView => {
        if (viewLoaded) {
            viewLoaded(loadedView);
        }
        setView(loadedView);
    };
    return (
        <>
            <div className={classes.esriViewer} ref={mapDiv} />
            {view && (
                <ViewerTools
                    view={view}
                    renderer={ViewerToolsRenderer.REACT}
                    tools={toolsGetter ? toolsGetter(view) : []}
                />
            )}
        </>
    );
};

export default EsriViewer;
