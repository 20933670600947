import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import React from 'react';

import ModelingProgressWrapper from '../ModelingProgress/ModelingProgressWrapper';
import useStyles from './styles';

interface IModellingProgressDialog {
    isOpen?: boolean;
    showcaseCode: string;
    onClose?: () => void;
}

const ModellingProgressDialog: React.FC<IModellingProgressDialog> = ({
    showcaseCode,
    isOpen,
    onClose,
}) => {
    const classes = useStyles();

    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    };

    return (
        <Dialog open={!!isOpen} classes={{ paper: classes.dialogPaper }}>
            <DialogContent className={classes.dialogContent}>
                <ModelingProgressWrapper showcaseCode={showcaseCode} />
            </DialogContent>

            <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={() => handleClose()}
            >
                <CloseIcon />
            </IconButton>
        </Dialog>
    );
};

export default ModellingProgressDialog;
