import { IModule } from 'redux-dynamic-modules';

import middlewares from './middleware';
import { IFeedbackModuleState, reducer } from './reducer';

export const FeedbackModule: IModule<IFeedbackModuleState> = {
    id: 'feedback-module',
    reducerMap: {
        feedbackState: reducer,
    },
    middlewares: middlewares,
};
