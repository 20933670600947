import jwtDecode from 'jwt-decode';

export const isJwtExpired = (token: string, expiryInSeconds = 0) => {
    if (typeof token !== 'string' || !token) throw new Error('Invalid token provided');

    let isExpired = false;
    const { exp } = jwtDecode(token) as { exp };
    const currentTime = new Date().getTime() / 1000;

    if (currentTime + expiryInSeconds > exp) isExpired = true;

    return isExpired;
};
