import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Theme,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles, withStyles } from '@mui/styles';
import React, { createContext, Dispatch, PropsWithChildren, SetStateAction, useState } from 'react';
import { useIntl } from 'react-intl';

import messages from './messages';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    title: {
      fontSize: '1.8rem',
      lineHeight: '1.8rem',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[600],
    },
    globalDialog: {
      minWidth: '50vw',
    },
  }),
);

interface IDialogTitleProps {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const WipDialogTitle = (props: IDialogTitleProps) => {
  const { children, onClose, ...other } = props;
  const classes = useStyles();
  return (
    <DialogTitle variant={'h2'} className={classes.root} {...other}>
      {children}
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const WipDialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(DialogContent);

const WipDialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(DialogActions);

export interface IDialogContext {
  globalDialogOpen: boolean;
  setGlobalDialogOpen: Dispatch<SetStateAction<boolean>>;
  setGlobalDialogTitle: Dispatch<SetStateAction<string | null>>;
  setGlobalDialogContent: Dispatch<SetStateAction<string | null>>;
}

export const DialogContext = createContext<IDialogContext>({
  globalDialogOpen: false,
  setGlobalDialogOpen: () => {},
  setGlobalDialogTitle: () => {},
  setGlobalDialogContent: () => {},
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDialogProps {}

const GlobalDialogProvider: React.FC<PropsWithChildren<IDialogProps>> = (
  props: PropsWithChildren<IDialogProps>,
) => {
  const classes = useStyles();
  const { children } = props;

  const [globalDialogOpen, setGlobalDialogOpen] = useState<boolean>(false);
  const [globalDialogTitle, setGlobalDialogTitle] = useState<string | null>(null);
  const [globalDialogContent, setGlobalDialogContent] = useState<string | null>(null);

  const value = {
    globalDialogOpen,
    setGlobalDialogOpen,
    setGlobalDialogTitle,
    setGlobalDialogContent,
  };

  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = () => {
    setGlobalDialogOpen(false);
    setGlobalDialogTitle(null);
    setGlobalDialogContent(null);
  };

  // translations
  const intl = useIntl();
  const transTitle = intl.formatMessage({ ...messages.title });
  const transContent = intl.formatMessage({ ...messages.content });
  const transButton = intl.formatMessage({ ...messages.button });

  return (
    <DialogContext.Provider value={value}>
      {children}

      {globalDialogOpen && (
        <Dialog
          onClose={handleClose}
          aria-labelledby="demo-dialog-title"
          open={globalDialogOpen}
          maxWidth="lg"
          // fullScreen={fullScreen}
          fullWidth
          scroll="paper"
          className={classes.globalDialog}
        >
          <WipDialogTitle id="demo-dialog-title" onClose={handleClose}>
            {globalDialogTitle ? globalDialogTitle : transTitle}
          </WipDialogTitle>
          <WipDialogContent dividers>
            <Typography paragraph gutterBottom>
              {globalDialogContent ? globalDialogContent : transContent}
            </Typography>
          </WipDialogContent>
          <WipDialogActions>
            <Button autoFocus onClick={handleClose} variant="contained" color="primary">
              {transButton}
            </Button>
          </WipDialogActions>
        </Dialog>
      )}
    </DialogContext.Provider>
  );
};

export default GlobalDialogProvider;
