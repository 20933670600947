import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'components__premium-dialog__title',
    defaultMessage: 'Work in progress',
  },
  content: {
    id: 'components__premium-dialog__content',
    defaultMessage:
      'This app is under development. This functionality is activated in the full version.',
  },
  button: {
    id: 'components__premium-dialog__button',
    defaultMessage: 'Close',
  },
});
