import React, { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { DialogContext } from '../../dialogs/DialogProvider/DialogProvider';
import feedbackMessages from '../DialogFactory/Feedback/messages';
import Feedback from './Feedback/Feedback';

/**
 * Dialog factory
 * - define all dialogs name here
 */
export enum DF {
    FEEDBACK = 'FEEDBACK',
    UPDATE_MODELING_SHOWCASE_ITEM = 'UPDATE_MODELING_SHOWCASE_ITEM',
}

const DialogFactory: React.FC = () => {
    const location = useLocation();
    const { createDialog } = useContext(DialogContext);

    // translations
    const intl = useIntl();
    const transFeedbackTitle = intl.formatMessage({ ...feedbackMessages.title });

    useEffect(() => {
        createDialog({
            id: DF.FEEDBACK,
            title: transFeedbackTitle,
            maxWidth: 'sm',
            fullWidth: true,
            dialogContent: <Feedback location={location.pathname} />,
        });
    }, [location]);

    return <></>;
};

export default DialogFactory;
