/**
 * Custom theme forked form MUI default theme
 * https://material-ui.com/customization/default-theme/
 */

import './fonts.css';

import { createTheme, lighten } from '@mui/material';

export const fontFamily = {
    primary: 'Montserrat',
    secondary: 'Montserrat',
};

export const template = {
    bodyBgColor: '#ffffff',

    headerBarColor: '#000000',
    headerBarBackground: '#ffffff',
    headerCircleBgColor: '#000000',
    headerCircleTextColor: '#ffffff',

    dashboardCardBg: 'rgb(236, 28, 36)',
    dashboardCardBgDisabled: '#c7c7c7',
    dashboardCardSvgColor: '#ffffff',
    dashboardCardSvgColorDisabled: '#ffffff',
    dashboardCardTitleColor: '#73002A',
    dashboardCardTitleColorDisabled: '#c7c7c7',
};

const theme = createTheme({
    palette: {
        primary: {
            main: 'rgb(236, 28, 36)',
            light: '#8f0034',
            dark: '#4d001b',
        },
        secondary: {
            main: '#ffdd00',
            light: '#ffff56',
            dark: '#c7bb00',
        },
        success: {
            main: '#34b88d',
            light: '#35c49c',
            dark: '#008760',
            // contrastText: "",
        },
        warning: {
            main: '#ff7f00',
            light: '#f93',
        },
        error: {
            main: '#eb445a',
            light: '#ed576b',
            dark: '#cf3c4f',
            // contrastText: "",
        },
        text: {
            primary: '#000',
            secondary: '#819099',
            disabled: '#819099',
            // hint: "",
        },
        grey: {
            50: '#edf0f2', // table bg, footer bg
            100: '#dae1e6', // avatar bg
            200: '#bcc9d1', // header color
            300: '#819099', // avatar color
            400: '#f7f9fa', // table toolbar
            500: '#dcdcdc',
            600: '#2d2d2d',
            700: '#707070',
            800: '#B2B2B2',
            900: '#819099',
            A100: '#dae1e5', // border
            // A200: "",
            // A400: "",
            // A700: "",
        },
        // issue statuses
        info: {
            main: '#ffa600', // open
            50: '#bcc9d1', // voided
            100: '#087cd9', // answered, work completed
            200: '#819099', // draft
            300: '#7a77d9', // ready to inspect
            400: '#ff495c', // not approved, in dispute
        },
        common: {
            white: '#fff',
            black: '#4a4949',
        },
        background: {
            // paper: "",
            default: '#ffffff',
        },
        action: {
            // active: "",
            // hover: "",
            // hoverOpacity: number,
            // selected: "",
            // selectedOpacity: number,
            // disabled: "",
            // disabledBackground: "",
            // disabledOpacity: number,
            // focus: "",
            // focusOpacity: number,
            // activatedOpacity: number,
        },
        mode: 'light', // theme style switching
    },
    typography: {
        htmlFontSize: 14,
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            `${fontFamily.primary}`,
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
    shape: {
        borderRadius: 4,
    },
    spacing: factor => `${0.6 * factor}rem`, // bootstrap strategy
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                '@global': props => {
                    console.log(props);
                    return {
                        '*': {
                            'scrollbar-width': 'thin',
                        },
                        // custom scrollbar
                        '*::-webkit-scrollbar': {
                            width: '10px !important',
                            height: '10px !important',
                            backgroundColor: 'transparent',
                        },
                        '*::-webkit-scrollbar-track': {
                            '-webkit-box-shadow': 'none !important',
                            backgroundColor: 'transparent',
                            borderRadius: props.shape.borderRadius * 2,
                        },
                        '*::-webkit-scrollbar-thumb': {
                            backgroundColor: props.palette.grey[100],
                            borderRadius: props.shape.borderRadius * 2,
                        },
                        body: {
                            // keep safe area margin in native devices
                            marginTop: 'env(safe-area-inset-top) !important',
                            marginBottom: 'env(safe-area-inset-bottom) !important',
                            paddingRight: '0 !important',

                            // forge viewer
                            '& .adsk-viewing-viewer.dark-theme .adsk-button.active, .adsk-viewing-viewer.dark-theme .adsk-button:focus':
                                {
                                    color: `${props.palette.primary.main} !important`,
                                },
                            '& .adsk-viewing-viewer.dark-theme .adsk-button:hover': {
                                color: `${props.palette.primary.main} !important`,
                                border: `1px solid ${props.palette.primary.main} !important`,
                            },
                            '& .adsk-viewing-viewer .adsk-toolbar': {
                                // top: "10px",
                                bottom: '10px',
                                zIndex: 9999,
                                '&>div': {},
                                '& .adsk-button': {
                                    width: '19px',
                                    height: '19px',
                                    padding: '4px',
                                    '& .adsk-button-icon': {
                                        fontSize: '19px',
                                    },
                                },
                            },
                            "& [class*='MuiCheckbox-colorPrimary']": {
                                width: '23px',
                                height: '23px',
                                '& svg': {
                                    fontSize: '22px',
                                    fill: props.palette.secondary.main,
                                },
                            },
                        },
                        sup: {
                            fontSize: '70%',
                        },
                    };
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: props => ({
                    zIndex: props.theme.zIndex.drawer - 1,
                    color: template.headerBarColor,
                    backgroundColor: template.headerBarBackground,
                    flexGrow: 1,
                    transition: theme.transitions.create(['margin', 'width'], {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
                }),
            },
        },
        MuiButton: {
            defaultProps: {
                size: 'small',
            },
            // https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/Button/Button.js
            styleOverrides: {
                contained: props => ({
                    backgroundColor: props.theme.palette.common.black,
                    '&:hover, &:focus': {
                        backgroundColor: lighten(props.theme.palette.common.black, 0.3),
                    },
                }),
                containedPrimary: {
                    fontWeight: 400,
                },
                containedSizeSmall: props => ({
                    minWidth: '170px',
                    padding: '8px 16px',
                    fontSize: props.theme.typography.pxToRem(12),
                }),
                containedSizeLarge: props => ({
                    fontSize: props.theme.typography.pxToRem(12),
                    padding: '8px 20px',
                    minWidth: '170px',
                    [props.theme.breakpoints.up('md')]: {
                        fontSize: props.theme.typography.pxToRem(14),
                        padding: '8px 22px',
                        minWidth: '270px',
                    },
                }),
            },
        },
        MuiFilledInput: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiFormControl: {
            defaultProps: {
                margin: 'dense',
            },
            styleOverrides: {
                marginDense: props => ({
                    marginTop: props.theme.spacing(1),
                    marginBottom: props.theme.spacing(1),
                }),
            },
        },
        MuiFormHelperText: {
            defaultProps: {
                margin: 'dense',
            },
            styleOverrides: {
                root: props => ({
                    fontSize: props.theme.typography.pxToRem(11),
                }),
                contained: props => ({
                    marginLeft: props.theme.spacing(1),
                    marginRight: props.theme.spacing(1),
                }),
            },
        },
        MuiIconButton: {
            defaultProps: {
                size: 'small',
            },
            styleOverrides: {
                root: {},
                colorPrimary: {
                    // color: theme.palette.common.white,
                    // background: theme.palette.primary.main,
                    '&:hover, &:focus': {
                        // color: theme.palette.common.white,
                        // background: theme.palette.primary.dark,
                    },
                },
                sizeSmall: {
                    padding: 0,
                },
            },
        },
        MuiInputBase: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiInputLabel: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiListItem: {
            defaultProps: {
                dense: true,
            },
        },
        MuiOutlinedInput: {
            defaultProps: {
                margin: 'dense',
            },
            styleOverrides: {
                root: props => ({
                    '& $notchedOutline': {
                        color: theme.palette.text.primary,
                        borderColor: theme.palette.grey.A100,
                    },
                    '&:hover $notchedOutline': {
                        color: theme.palette.common.black,
                        borderColor: theme.palette.grey.A100,
                    },
                    '&$focused $notchedOutline': {
                        color: theme.palette.common.black,
                        borderColor: theme.palette.grey.A100,
                    },
                    color: theme.palette.text.primary,
                    minWidth: '170px',
                    [props.theme.breakpoints.up('md')]: {
                        minWidth: '270px',
                    },
                }),
                notchedOutline: props => ({
                    color: props.theme.palette.text.primary,
                }),
                // marginDense: {
                //     margin: 0,
                // },
            },
        },
        MuiFab: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiTable: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiTextField: {
            defaultProps: {
                margin: 'dense',
            },
            styleOverrides: {
                root: props => ({
                    fontSize: props.theme.typography.pxToRem(12),
                }),
            },
        },
        MuiToolbar: {
            defaultProps: {
                variant: 'dense',
            },
            styleOverrides: {
                root: {
                    minHeight: 0,
                },
                dense: {
                    minHeight: 0,
                },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: props => ({
                    fontSize: props.theme.typography.pxToRem(12),
                }),
            },
        },
        MuiTableCell: {
            styleOverrides: {
                sizeSmall: {
                    padding: '6px 12px',
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: props => ({
                    backgroundColor: props.theme.palette.grey['100'],
                }),
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                // override color for secondary color checkbox
                colorSecondary: props => ({
                    color: `${props.theme.palette.primary.main} !important`,
                    '&$checked': {
                        color: `${props.theme.palette.primary.main} !important`,
                    },
                }),
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: props => ({
                    color: props.theme.palette.primary.main,
                }),
                colorSecondary: props => ({
                    '&$checked': {
                        color: props.theme.palette.primary.main,
                    },
                }),
            },
        },
        MuiInput: {
            styleOverrides: {
                root: props => ({
                    fontSize: props.theme.typography.pxToRem(12),
                }),
            },
        },
        MuiSelect: {
            styleOverrides: {
                // root: props => ({
                //     fontSize: theme.typography.pxToRem(12),
                // }),
            },
        },
    },
});

theme.typography.h1 = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: '1.5em',
    letterSpacing: 'normal',

    [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(16),
    },
};

theme.typography.h2 = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(28),
    lineHeight: '1.5em',
    letterSpacing: 'normal',
    color: theme.palette.text.primary,

    [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(24),
    },
};

theme.typography.h3 = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: '1.5em',
    letterSpacing: 'normal',
    color: theme.palette.text.primary,

    [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(16),
    },
};

theme.typography.h4 = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: '1.5em',
    letterSpacing: 'normal',
    color: theme.palette.text.primary,
};

theme.typography.h5 = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: '1.5em',
    letterSpacing: 'normal',
    color: theme.palette.text.primary,
};

theme.typography.h6 = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: '1.5em',
    letterSpacing: 'normal',
    color: theme.palette.text.primary,
};

theme.typography.subtitle1 = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 300,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: '1.5em',
    letterSpacing: 'normal',
};

theme.typography.subtitle2 = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 300,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: '1.5em',
    letterSpacing: 'normal',
};

theme.typography.body1 = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: '1.5em',
    letterSpacing: 'normal',

    [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(12),
    },
};

theme.typography.body2 = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 300,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: '1.5em',
    letterSpacing: 'normal',

    [theme.breakpoints.down('sm')]: {},
};

theme.typography.button = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: '1.5em',
    letterSpacing: 'normal',

    [theme.breakpoints.down('sm')]: {},
};

theme.typography.caption = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 300,
    fontSize: theme.typography.pxToRem(13),
    lineHeight: '1.5em',
    letterSpacing: 'normal',

    [theme.breakpoints.down('sm')]: {},
};

theme.typography.overline = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 300,
    fontSize: theme.typography.pxToRem(11),
    lineHeight: '1.5em',
    letterSpacing: 'normal',

    [theme.breakpoints.down('sm')]: {},
};

theme.mixins.toolbar = {
    minHeight: 86,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
        minHeight: 76,
    },
    [theme.breakpoints.up('sm')]: {
        minHeight: 96,
    },
};

export default theme;
