import { defineMessages } from 'react-intl';

export default defineMessages({
    labelSurfaces: {
        id: 'components.filterBar.labelSurfaces',
        defaultMessage: 'Surfaces',
    },
    labelSia: {
        id: 'components.filterBar.labelSia',
        defaultMessage: 'SIA',
    },
    labelFunctions: {
        id: 'components.filterBar.labelFunctions',
        defaultMessage: 'Functions',
    },
    labelDisplayOnlySurfaceModel: {
        id: 'components.filterBar.labelDisplayOnlySurfaceModel',
        defaultMessage: 'Display only surface model',
    },
    labelDrilldown: {
        id: 'components.filterBar.labelDrilldown',
        defaultMessage: 'Drilldown',
    },
    labelHideInactive: {
        id: 'components.filterBar.labelHideInactive',
        defaultMessage: 'Hide inactive',
    },
    labelGhostHidden: {
        id: 'components.filterBar.labelGhostHidden',
        defaultMessage: 'Ghost hidden',
    },
    labelFaceView: {
        id: 'components.filterBar.labelFaceView',
        defaultMessage: 'Face view',
    },
    labelProjectView: {
        id: 'components.filterBar.labelProjectView',
        defaultMessage: 'Project view',
    },
    labelTotal: {
        id: 'components.filterBar.labelTotal',
        defaultMessage: 'Total',
    },
    labelFilter: {
        id: 'components.filterBar.labelFilter',
        defaultMessage: 'Filter',
    },
    labelSiaArea: {
        id: 'components.filterBar.labelSiaArea',
        defaultMessage: 'SIA Area',
    },
    labelLot: {
        id: 'components.filterBar.labelLot',
        defaultMessage: 'Lot',
    },
    labelUnclassified: {
        id: 'components.filterBar.labelUnclassified',
        defaultMessage: 'Unclassified',
    },

    gf: {
        id: 'components.filterBar.data.key.gf',
        defaultMessage: 'GF (floor area)',
    },
    ngf: {
        id: 'components.filterBar.data.key.ngf',
        defaultMessage: 'NGF (net floor area)',
    },
    nf: {
        id: 'components.filterBar.data.key.nf',
        defaultMessage: 'NF (usable floor area)',
    },
    hnf: {
        id: 'components.filterBar.data.key.hnf',
        defaultMessage: 'HNF (Main usable floor area)',
    },
    nnf: {
        id: 'components.filterBar.data.key.nnf',
        defaultMessage: 'NNF (auxiliary usable floor area)',
    },
    vf: {
        id: 'components.filterBar.data.key.vf',
        defaultMessage: 'VF (circulation area)',
    },
    ff: {
        id: 'components.filterBar.data.key.ff',
        defaultMessage: 'FF (functional area)',
    },
    agf: {
        id: 'components.filterBar.data.key.agf',
        defaultMessage: 'AGF (exterior floor area)',
    },
    aff: {
        id: 'components.filterBar.data.key.aff',
        defaultMessage: 'AFF',
    },
    anf: {
        id: 'components.filterBar.data.key.anf',
        defaultMessage: 'ANF (exterior usable floor area)',
    },
    angf: {
        id: 'components.filterBar.data.key.angf',
        defaultMessage: 'ANGF (exterior net floor area)',
    },
    avf: {
        id: 'components.filterBar.data.key.avf',
        defaultMessage: 'AVF (exterior circulation)',
    },
    kf: {
        id: 'components.filterBar.data.key.kf',
        defaultMessage: 'KF (construction)',
    },
    kft: {
        id: 'components.filterBar.data.key.kft',
        defaultMessage: 'KFT (construction load bearing)',
    },
    kfn: {
        id: 'components.filterBar.data.key.kfn',
        defaultMessage: 'KFN (construction not load bearing)',
    },
    akf: {
        id: 'components.filterBar.data.key.akf',
        defaultMessage: 'AKF (exterior construction)',
    },
    akft: {
        id: 'components.filterBar.data.key.akft',
        defaultMessage: 'AKFT (exterior construction load bearing)',
    },
    akfn: {
        id: 'components.filterBar.data.key.akfn',
        defaultMessage: 'AKFN (exterior construction not load bearing)',
    },
});
