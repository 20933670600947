import { useKeycloak } from '@react-keycloak/web';
import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';

export function PrivateRoute({ component: Component, ...rest }) {
    const { keycloak, initialized: keycloakInitialized } = useKeycloak();

    useEffect(() => {
        if (keycloakInitialized && keycloak?.authenticated == false) {
            keycloak.login();
        }
    }, [keycloakInitialized]);

    return (
        <Route
            {...rest}
            render={props =>
                keycloakInitialized && keycloak?.authenticated == true ? (
                    <Component {...props} />
                ) : (
                    ''
                )
            }
        />
    );
}
