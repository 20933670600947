import { createSelector } from 'reselect';

import { INotifierModuleState, INotifierState } from './reducer';

export function selectNotifierState(state: INotifierModuleState): INotifierState {
  return state.notifierState;
}

export const selectNotifications = createSelector(
  selectNotifierState,
  state => state.notifications,
);
