import { createSelector } from 'reselect';

// import { State } from "../../../redux/type";
import { IAppModuleState, IAppState } from './reducer';

export function selectAppState(state: IAppModuleState): IAppState {
  return state.appState;
}

export const selectPageTitle = createSelector(selectAppState, state => state.pageTitle);
export const selectProjectTitle = createSelector(selectAppState, state => state.projectTitle);
