import { useContext } from 'react';

import { HeaderContext, IHeaderContext } from './HeaderProvider';

const useHeaderContent = () => {
    const { headerContent, setHeaderContent } = useContext<IHeaderContext>(HeaderContext);

    return {
        headerContent,
        setHeaderContent,
    };
};

export default useHeaderContent;
