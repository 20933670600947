import { AnyAction } from 'redux';

import { ActionTypes, INotification } from './action';

export interface INotifierModuleState {
  notifierState: INotifierState;
}

export interface INotifierState {
  nextNotification: number;
  notifications: INotification[];
}

export const initialState: Readonly<INotifierState> = {
  nextNotification: -1, // used for notifications keys
  notifications: [],
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export function reducer(state: INotifierState = initialState, action: AnyAction): INotifierState {
  switch (action.type) {
    case ActionTypes.AddNotification: {
      const key = state.nextNotification + 1;

      return {
        ...state,
        nextNotification: key,
        // add notification with incremented key at the start of the list
        notifications: [
          {
            ...action.payload.notification,
            key: key,
          },
          ...state.notifications,
        ],
      };
    }

    case ActionTypes.RemoveNotification: {
      return {
        ...state,
        // remove notification from the list for given key
        notifications: state.notifications.filter(
          notification => notification.key !== action.payload.key,
        ),
      };
    }

    default:
      return state;
  }
}
