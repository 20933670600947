import { AnyAction } from 'redux';

import { ActionTypes } from './action';

export interface IFeedbackModuleState {
    feedbackState: IFeedbackState;
}

export interface IFeedbackState {
    loading: boolean;
}

export const initialState: Readonly<IFeedbackState> = {
    loading: false,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export function reducer(state: IFeedbackState = initialState, action: AnyAction): IFeedbackState {
    switch (action.type) {
        // feedback list
        case ActionTypes.OnFeedbackSend: {
            return {
                ...state,
                loading: true,
            };
        }
        case ActionTypes.OnFeedbackSendDone:
        case ActionTypes.OnFeedbackSendFail: {
            return {
                ...state,
                loading: false,
            };
        }

        default:
            return state;
    }
}
