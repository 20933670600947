import { ApiClient } from '@iolabs/api-client';
import { AxiosResponse } from 'axios';

import config from '../../../../config/config';
import { IViewerProxyResponse } from './types';

const api = new ApiClient('/ForgeViewer', config.api.baseUrl);

export const viewerFileInfo = (
    token: string,
    urn: string,
    projectId: string,
    fetchLatest = true,
): Promise<IViewerProxyResponse> => {
    ApiClient.setAuthorizationToken(token);

    const url = `/Derivative/${encodeURIComponent(
        urn,
    )}/Viewer?projectId=${projectId}&fetchLatest=${fetchLatest}`;

    return api
        .get<IViewerProxyResponse>(url)
        .then((response: AxiosResponse<IViewerProxyResponse>) => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            return Promise.reject(error);
        });
};

export const viewerToken = (token: string): Promise<string> => {
    ApiClient.setAuthorizationToken(token);

    const url = `/Viewer/Token`;

    return api
        .get<string>(url)
        .then((response: AxiosResponse<string>) => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            return Promise.reject(error);
        });
};
