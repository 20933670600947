import Basemap from '@arcgis/core/Basemap';
import SceneView from '@arcgis/core/views/SceneView';
import BasemapGallery from '@arcgis/core/widgets/BasemapGallery';
import Daylight from '@arcgis/core/widgets/Daylight';
import Widget from '@arcgis/core/widgets/Widget';
import React from 'react';

import EsriTools from './EsriTools';
import { IToolDef } from './EsriViewer';
import ReactTools from './ReactTools';

export enum ViewerToolsRenderer {
    ESRI,
    REACT,
}
interface IViewerToolsProps {
    view: SceneView;
    renderer: ViewerToolsRenderer;
    tools?: IToolDef[];
}

export interface IToolsProps {
    view: SceneView;
    tools?: Array<Node | string | Widget>;
}

const getTools = (view: SceneView): Array<Node | string | Widget> => {
    return [
        new BasemapGallery({
            view: view,
            // activeBasemap: Basemap.fromId('arcgis-terrain'),
            source: [
                Basemap.fromId('arcgis-terrain'),
                Basemap.fromId('satellite'),
                Basemap.fromId('streets-vector'),
            ],
        }),
        new Daylight({
            view: view,
        }),
        // new ShadowCast({ view: view });
    ];
};

const ViewerTools: React.FC<IViewerToolsProps> = ({ view, renderer, tools }) => {
    if (tools) {
        switch (renderer) {
            case ViewerToolsRenderer.ESRI:
                return <EsriTools view={view} tools={tools} />;
            case ViewerToolsRenderer.REACT:
                return <ReactTools tools={tools} />;
        }
    } else {
        return null;
    }
};

export default ViewerTools;
