import { addNotification, INotification } from '@iolabs/notifier';
import { AnyAction, Middleware } from 'redux';

import { ActionTypes } from './action';

const onAppStartMiddleware: Middleware =
  ({ dispatch }) =>
  next =>
  (action: AnyAction) => {
    if (action.type === ActionTypes.OnStart) {
      // TODO: implement on start actions
    }

    if (action.type === ActionTypes.OnError) {
      const { errorCode, errorMessage } = action.payload;
      const notification: INotification = {
        key: errorCode ? errorCode : 1,
        variant: 'error',
        message: errorMessage,
      };
      return dispatch(addNotification({ notification }));
    }

    return next(action);
  };

export default [onAppStartMiddleware];
