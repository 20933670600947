import { DispatchAction } from '@iolabs/redux-utils';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import Keycloak, { KeycloakProfile } from 'keycloak-js';
import React, { PropsWithChildren } from 'react';
import { useDispatch } from 'react-redux';

import config from '../../config/config';
import { onEvent, onTokens } from './action';

const keycloakDefaultInitConfig: Keycloak.KeycloakInitOptions = {
    checkLoginIframe: false,
    // adapter: "cordova",
};

interface ISecurityProvider {
    keycloakInitConfig?: Keycloak.KeycloakInitOptions;
}

export interface KeycloakProfileConfig extends KeycloakProfile {
    attributes: {
        forgeID: string[];
    };
}

const SecurityProvider: React.FC<PropsWithChildren<ISecurityProvider>> = (
    props: PropsWithChildren<ISecurityProvider>,
) => {
    const { keycloakInitConfig, children } = props;
    const dispatch = useDispatch<DispatchAction>();

    const keycloak: Keycloak.KeycloakInstance = Keycloak({
        clientId: config.security.clientId,
        realm: config.security.realm,
        url: config.security.realmUrl,
    });

    return (
        <ReactKeycloakProvider
            authClient={keycloak}
            initOptions={keycloakInitConfig ? keycloakInitConfig : keycloakDefaultInitConfig}
            onEvent={event =>
                dispatch(
                    onEvent({
                        event: event,
                    }),
                )
            }
            onTokens={tokens => {
                dispatch(onTokens(tokens));
            }}
        >
            <>{children}</>
        </ReactKeycloakProvider>
    );
};

export default SecurityProvider;
