import { useGlobalDialog } from '@iolabs/wip-dialog';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { Fab, Tooltip } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

import messages from './messages';
import useStyles from './styles';

const FloatingActionButton: React.FC = () => {
    const classes = useStyles();
    const { setGlobalDialogOpen } = useGlobalDialog();

    // translations
    const intl = useIntl();
    const transTooltip = intl.formatMessage({ ...messages.tooltip });

    return (
        <Tooltip title={transTooltip}>
            <Fab
                color="primary"
                aria-label="feedback"
                classes={{ root: classes.fabRoot }}
                onClick={() => setGlobalDialogOpen(true)}
            >
                <QuestionAnswerIcon fontSize="small" />
            </Fab>
        </Tooltip>
    );
};

export default FloatingActionButton;
