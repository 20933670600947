import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import '../../theme/variables.css';

import { Notifier } from '@iolabs/notifier';
import { IonRouterOutlet } from '@ionic/react';
import { SnackbarProvider } from 'notistack';
import React, { PropsWithChildren } from 'react';
import { Route } from 'react-router';

import MinimalisticPage from '../../pages/MinimalisticPage/MinimalisticPage';
import useStyles from './styles';

const PublicApp: React.FC<PropsWithChildren> = () => {
    const classes = useStyles();
    // const match = useMatch();
    return (
        <>
            <SnackbarProvider
                maxSnack={3}
                dense
                preventDuplicate
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                classes={{
                    variantSuccess: classes.success,
                    variantError: classes.error,
                    variantWarning: classes.warning,
                    variantInfo: classes.info,
                }}
            >
                <IonRouterOutlet>
                    <Route path={`minimal`} component={MinimalisticPage} />
                </IonRouterOutlet>
                <Notifier />
            </SnackbarProvider>
        </>
    );
};

export default PublicApp;
