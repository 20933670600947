import { createAction, DispatchAction } from '@iolabs/redux-utils';

export enum ActionTypes {
  OnStart = 'app/ON_START',
  OnError = 'app/ON_ERROR',
  OnPageTitle = 'app/ON_PAGE_TITLE',
  OnProjectTitle = 'app/ON_PROJECT_TITLE',
}

export type ErrorOptions = {
  errorCode?: number;
  errorMessage: string;
};

export type PageTitleOptions = {
  pageTitle: string;
};

export type ProjectTitleOptions = {
  projectTitle: string;
};

const Actions = {
  onStart: () => createAction(ActionTypes.OnStart),
  onError: (options: ErrorOptions) => createAction(ActionTypes.OnError, options),
  onPageTitle: (options: PageTitleOptions) => createAction(ActionTypes.OnPageTitle, options),
  onProjectTitle: (options: ProjectTitleOptions) =>
    createAction(ActionTypes.OnProjectTitle, options),
};

export type OnProjectTitleAction = typeof Actions.onProjectTitle;

export function onStart(): DispatchAction {
  return async (dispatch: DispatchAction) => {
    dispatch(Actions.onStart());
  };
}

export function onError(options: ErrorOptions): DispatchAction {
  return async (dispatch: DispatchAction) => {
    dispatch(Actions.onError(options));
  };
}

export function onPageTitle(options: PageTitleOptions): DispatchAction {
  return async (dispatch: DispatchAction) => {
    dispatch(Actions.onPageTitle(options));
  };
}

export function onProjectTitle(options: ProjectTitleOptions): DispatchAction<OnProjectTitleAction> {
  return async (dispatch: DispatchAction) => {
    dispatch(Actions.onProjectTitle(options));
  };
}
