import {
    Box,
    IconButton,
    LinearProgress,
    Link,
    Paper,
    Popover,
    Theme,
    Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useKeycloak } from '@react-keycloak/web';
import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { DialogContext } from '../../dialogs/DialogProvider/DialogProvider';
import { IModelingShowcaseItem } from '../../packages/Api/data/modelingShowcase/types';
import { KeycloakRealmRole } from '../../redux/keyclock/types';
import { DF } from '../DialogFactory/DialogFactory';
import UpdateModelingShowcaseItem from '../DialogFactory/UpdateModelingShowcaseItem/UpdateModelingShowcaseItem';
import Icon from '../Icon/Icon';
import { IModelingItemProgress } from './ModelingProgressItem';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            overflow: 'hidden',
            pointerEvents: 'none',
        },
        paper: {
            minWidth: '160px',
            width: '300px',
            overflow: 'hidden',
            pointerEvents: 'all',
            position: 'relative',
        },
        paperContent: {
            padding: theme.spacing(1),
        },
        paperBox: {
            paddingLeft: theme.spacing(1.5),
            paddingRight: theme.spacing(1.5),
            paddingBottom: theme.spacing(1),
        },
        arrow: {
            position: 'relative',
            marginTop: '10px',
            '&::before': {
                backgroundColor: 'white',
                content: '""',
                display: 'block',
                position: 'absolute',
                width: 12,
                height: 12,
                top: -6,
                transform: 'rotate(45deg)',
                left: 'calc(50% - 6px)',
            },
        },
        title: {
            color: theme.palette.common.white,
            background: 'rgba(0, 0, 0, 0.6)',
            padding: theme.spacing(1, 0.5),
            fontSize: '0.9em',
            textAlign: 'center',
            position: 'absolute',
            transform: 'translate(-50%, 50%)',
            top: '-45px',
            whiteSpace: 'nowrap',
            transition: 'opacity 0.5s',
        },
        expand: {
            width: '35px',
            minWidth: '35px',
            position: 'absolute',
            right: theme.spacing(0.2),
            top: theme.spacing(0.2),
        },
        row: {
            display: 'flex',
        },
        rowItem: {
            flexGrow: 1,
            fontSize: theme.typography.pxToRem(12),
            color: 'inherit',
        },
        progress: {
            width: '75px',
            flexGrow: 0,
        },
        progressBar: {
            height: '5px',
        },
        progressBarInactive: {
            backgroundColor: 'grey',
        },
        progressBarActive: {
            backgroundColor: theme.palette.common.black,
        },
        content: {
            transition: 'max-height 0.5s',
            maxHeight: '0px',
            overflow: 'hidden',
            fontSize: theme.typography.pxToRem(10),
            '& table, & tbody': {
                width: '100%',
                '& th': {
                    textAlign: 'left',
                },
                '& tr': {
                    display: 'flex',
                    '& td:nth-child(1), & th:nth-child(1)': {
                        flex: 1,
                    },
                    '& td:nth-child(2), & th:nth-child(2)': {
                        width: '75px',
                    },
                },
            },
        },
        expanded: {
            maxHeight: '600px',
        },
        hidden: {
            opacity: 0,
        },
        md: {},
        editIcon: {
            position: 'absolute',
            right: theme.spacing(1),
            bottom: theme.spacing(1),
        },
    }),
);

interface IPushpinPopoverProps {
    id?: string | undefined;
    anchorEl: HTMLElement | null;
    item: IModelingItemProgress;
    zIndex?: number;
    handleClose?: () => void;
}

const PushpinPopover: React.FC<IPushpinPopoverProps> = (props: IPushpinPopoverProps) => {
    const { id, anchorEl, item, handleClose } = props;
    const classes = useStyles();

    const [itemState, setItemState] = useState<IModelingItemProgress>(item);
    const [enableEdit, setEnableEdit] = useState<boolean>(false);

    const { createDialog, toggleDialog } = useContext(DialogContext);
    const { keycloak, initialized: keycloakInitialized } = useKeycloak();

    useEffect(() => {
        Promise.resolve(keycloak?.hasRealmRole(KeycloakRealmRole?.DASHBOARD_ADMIN)).then(
            hasRealmRole => {
                setEnableEdit(!!hasRealmRole);
            },
        );
    }, [keycloakInitialized]);

    const handleSaved = (savedItem: IModelingShowcaseItem) => {
        setItemState({ ...item, progress: savedItem.itemProgress });
    };

    const handleEdit = () => {
        createDialog({
            id: DF.UPDATE_MODELING_SHOWCASE_ITEM,
            title: 'Update item',
            maxWidth: 'sm',
            fullWidth: true,
            dialogContent: <UpdateModelingShowcaseItem item={item} onSaved={handleSaved} />,
        });

        toggleDialog(DF.UPDATE_MODELING_SHOWCASE_ITEM);
    };
    const handleClickPaper = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (handleClose) {
            handleClose();
        }
        e.stopPropagation();
    };

    const opened = Boolean(anchorEl);

    return (
        <>
            <Box className={clsx(classes.title, { [classes.hidden]: opened })}>
                {item.title} [{itemState.progress}%]
            </Box>
            <Popover
                id={id}
                open={opened}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                className={classes.root}
                PaperProps={{
                    style: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        borderRadius: 0,
                    },
                }}
                style={{ zIndex: item.zIndex }}
            >
                <Box className={classes.arrow} />

                <Paper className={classes.paper} onClick={handleClickPaper}>
                    <Box className={classes.paperContent}>
                        <Box className={classes.row}>
                            <Typography
                                component={itemState.modelLink ? Link : 'span'}
                                target={'_blank'}
                                href={itemState.modelLink}
                                className={classes.rowItem}
                            >
                                {itemState.title}
                            </Typography>
                            <Typography className={clsx(classes.rowItem, classes.progress)}>
                                {itemState.progress} %
                            </Typography>
                        </Box>
                    </Box>
                    <LinearProgress
                        variant="determinate"
                        value={itemState.progress}
                        classes={{
                            root: classes.progressBar,
                            bar: classes.progressBarInactive,
                            barColorPrimary: classes.progressBarActive,
                        }}
                    />
                    <Box className={classes.paperContent}>
                        <Box className={clsx(classes.content, classes.expanded)}>
                            <ReactMarkdown
                                className={classes.md}
                                children={itemState.description}
                                remarkPlugins={[remarkGfm]}
                            />
                        </Box>
                    </Box>
                    {enableEdit && (
                        <IconButton onClick={handleEdit} className={classes.editIcon}>
                            <Icon name="pencil-solid" size={10} />
                        </IconButton>
                    )}
                </Paper>
            </Popover>
        </>
    );
};

export default PushpinPopover;
