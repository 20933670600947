import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';

import {
    ExtensionID as ColorizeExtensionID,
    ObjectsColoring,
    register as registerColorizeExtension,
} from '../Viewer/extensions/Viewing.Extensions.ColorizeExtension/Viewing.Extensions.ColorizeExtension';
import {
    ExtensionID as OptionsExtensionID,
    register as registerOptionsExtension,
} from '../Viewer/extensions/Viewing.Extensions.OptionsExtension/Viewing.Extensions.OptionsExtension';
import FilterBar from './FilterBar/FilterBar';

interface ISiaVisualizerProps {
    viewer: Autodesk.Viewing.Viewer3D;
    showcaseCode: string;
}

const SiaVisualizer: React.FC<ISiaVisualizerProps> = ({ viewer, showcaseCode }) => {
    // Filter bar
    const [coloringList, setColoringList] = useState<ObjectsColoring[]>();
    const [displayOnlySurfaceModel, setDisplayOnlySurfaceModel] = useState<boolean>(true);
    const [ghostHidden, setGhostHidden] = useState<boolean>(true);

    const [colorizeExtension, setColorizeExtension] = useState<any>();
    const [optionsExtension, setOptionsExtension] = useState<any>();
    const colorizeExtensionRef = React.useRef<any>();

    useEffect(() => {
        // todo better
        registerColorizeExtension();
        registerOptionsExtension();
    }, []);

    useLayoutEffect(() => {
        return () => {
            if (colorizeExtensionRef.current) {
                colorizeExtensionRef.current.clearSchema().then(() => {
                    console.log('scheme cleared');
                });
            }
        };
    }, []);

    useEffect(() => {
        if (viewer.model?.getInstanceTree()) {
            loadExtensions();
        } else {
            viewer.addEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, () => {
                loadExtensions();
            });
        }
    }, [viewer]);

    useEffect(() => {
        if (optionsExtension) {
            optionsExtension.ghostHidden(ghostHidden);
        }
    }, [ghostHidden]);

    const loadExtensions = useCallback(() => {
        viewer
            .loadExtension(ColorizeExtensionID, {
                mappingProperty: 'IfcGUID',
            })
            .then(extension => {
                colorizeExtensionRef.current = extension;
                setColorizeExtension(extension);
            });

        viewer
            .loadExtension(OptionsExtensionID, {
                setGhosting: false,
            })
            .then(extension => {
                setOptionsExtension(extension);
            });
    }, [viewer]);

    const [schemaApplied, setSchemaApplied] = useState<boolean>(false);
    useEffect(() => {
        function delay(time) {
            return new Promise(resolve => setTimeout(resolve, time));
        }
        delay(schemaApplied ? 0 : 1000).then(() => {
            // actual script
            if (colorizeExtension && coloringList) {
                colorizeExtension
                    .applySchema(coloringList, displayOnlySurfaceModel as boolean)
                    .then(() => {
                        console.log('scheme applied');
                        setSchemaApplied(true);
                    });
            }
        });
    }, [coloringList, colorizeExtension, displayOnlySurfaceModel]);

    const handleFilterBarColors = filterBarColors => {
        setColoringList(filterBarColors);
    };

    const handleFilterBarOptions = filterBarOptions => {
        setDisplayOnlySurfaceModel(filterBarOptions.displayOnlySurfaceModel);
        setGhostHidden(filterBarOptions.ghostHidden);
    };

    return (
        <>
            <FilterBar
                onOutputColors={handleFilterBarColors}
                onOutputOptions={handleFilterBarOptions}
                showcaseCode={showcaseCode}
            />
        </>
    );
};

export default SiaVisualizer;
