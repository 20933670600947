import ExtendableError from 'es6-error';

export default class BadRequestHttpError<T> extends ExtendableError {
  protected response?: T;

  constructor(message = 'Bad request!', response: T | undefined = undefined) {
    super(message);
    this.response = response;
  }

  public getResponse(): T | undefined {
    return this.response;
  }
}
