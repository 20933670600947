export interface IConfig {
  baseUrl: string;
}

export let config: IConfig;

export const setConfig = (conf: IConfig) => {
  config = conf;
};

export default function getConfig(): IConfig {
  return config;
}
