/**
 * Property helper
 */
export class ModelHelper {
    public static getAllModelIds(model: Autodesk.Viewing.Model): number[] {
        const instanceTree: Autodesk.Viewing.InstanceTree = model.getInstanceTree();
        if (!instanceTree) return [];
        const allDbIdsStr: string[] = Object.keys(instanceTree.nodeAccess.dbIdToIndex);
        return allDbIdsStr.map(function (id) {
            return parseInt(id);
        });
    }
}
