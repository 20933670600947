import { defineMessages } from 'react-intl';

export default defineMessages({
    togglePopovers: {
        id: 'components.modelingProgress.togglePopovers',
        defaultMessage: 'Toggle popovers',
    },
    showPopovers: {
        id: 'components.modelingProgress.showPopovers',
        defaultMessage: 'Show popovers',
    },
    hidePopovers: {
        id: 'components.modelingProgress.hidePopovers',
        defaultMessage: 'Hide popovers',
    },
    buttonStatus: {
        id: 'components.modelingProgress.buttonStatus',
        defaultMessage: 'Status',
    },
    buttonSurfaces: {
        id: 'components.modelingProgress.buttonSurfaces',
        defaultMessage: 'Surfaces',
    },
});
