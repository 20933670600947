import { Box } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { ReactElement } from 'react';

export const pushpinSize = 20;

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            position: 'absolute',
            pointerEvents: 'all',
            transform: 'translate(-50%, -50%)',
            zIndex: 20,
            width: pushpinSize,
            height: pushpinSize,
            borderRadius: '100%',
            cursor: 'pointer',
            borderWidth: '3px',
            borderStyle: 'solid',
        },
        skeletonBox: {},
        skeletonItem: {},
    }),
);

interface IPushpinWrapperProps {
    item: any;
    renderContent?: (
        item: any,
        anchorElement: HTMLElement | null,
        handleClose?: () => void,
    ) => ReactElement;
}

const PushpinWrapper: React.FC<IPushpinWrapperProps> = ({ item, renderContent }) => {
    const classes = useStyles();

    const [buttonEl, setButtonEl] = React.useState<HTMLElement | null>(null);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const open = Boolean(anchorEl);
    const id = open ? 'pushpin-popover' : undefined;

    const handleClickButon = () => {
        if (anchorEl) {
            setAnchorEl(null);
        } else {
            setAnchorEl(buttonEl);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box>
            <div
                className={classes.root}
                aria-describedby={id}
                style={{
                    backgroundColor: `#73002A`,
                    borderColor: '#ffffff',
                }}
                ref={c => setButtonEl(c)}
                onClick={handleClickButon}
            />
            {renderContent && renderContent(item, anchorEl, handleClose)}
        </Box>
    );
};

export default PushpinWrapper;
