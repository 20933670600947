import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'components.dialogs.updateModelingShowcaseItemMessages.title',
        defaultMessage: 'Update modeling showcase item', // Feedback
    },

    fieldType: {
        id: 'components.dialogs.feedbackDialog.fieldType',
        defaultMessage: 'Type', // Typ
    },
    fieldTypeQuestion: {
        id: 'components.dialogs.feedbackDialog.fieldTypeQuestion',
        defaultMessage: 'Question', // Frage
    },
    fieldTypeProblem: {
        id: 'components.dialogs.feedbackDialog.fieldTypeProblem',
        defaultMessage: 'Problem', // Problem
    },
    fieldTypeSuggestion: {
        id: 'components.dialogs.feedbackDialog.fieldTypeSuggestion',
        defaultMessage: 'Suggestion', // Anregung
    },
    fieldSubject: {
        id: 'components.dialogs.feedbackDialog.fieldSubject',
        defaultMessage: 'Subject', // Thema
    },
    fieldMessage: {
        id: 'components.dialogs.feedbackDialog.fieldMessage',
        defaultMessage: 'Description', // Beschreibung
    },

    placeholderType: {
        id: 'components.dialogs.feedbackDialog.placeholderType',
        defaultMessage: 'Please enter a type', // Bitte wählen Sie einen Typ
    },
    placeholderSubject: {
        id: 'components.dialogs.feedbackDialog.placeholderSubject',
        defaultMessage: 'Please enter a type', // Bitte geben Sie einen Betreff ein
    },
    placeholderMessage: {
        id: 'components.dialogs.feedbackDialog.placeholderMessage',
        defaultMessage: 'Please enter a description', // Bitte geben Sie eine Beschreibung ein
    },

    btnSend: {
        id: 'components.dialogs.feedbackDialog.btnSend',
        defaultMessage: 'Send', // Abschicken
    },

    validationDetails: {
        id: 'components.dialogs.feedbackDialog.validationType',
        defaultMessage: 'Please select a type', // Bitte wählen Sie einen Typ
    },
    validationSubject: {
        id: 'components.dialogs.feedbackDialog.validationSubject',
        defaultMessage: 'Please enter a subject', // Bitte geben Sie einen Betreff ein
    },
    validationMessage: {
        id: 'components.dialogs.feedbackDialog.validationMessage',
        defaultMessage: 'Please enter a description', // Bitte geben Sie eine Beschreibung ein
    },

    messageSuccess: {
        id: 'components.dialogs.feedbackDialog.messageSuccess',
        defaultMessage: 'Your feedback was successfully sent.', // Ihr Feedback wurde erfolgreich gesendet.
    },
    messageError: {
        id: 'components.dialogs.feedbackDialog.messageError',
        defaultMessage: 'Error during sending your feedback.', // Fehler beim Senden Ihrer Rückmeldung.
    },
});
