import { ApiClient } from '@iolabs/api-client';
import { Box, Checkbox, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { AxiosResponse } from 'axios';
import clsx from 'clsx';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Icon from '../../../components/Icon/Icon';
import config from '../../../config/config';
import { ObjectsColoring } from '../../Viewer/extensions/Viewing.Extensions.ColorizeExtension/Viewing.Extensions.ColorizeExtension';
import messages from './messages';
import useStyles from './styles';

const api = new ApiClient('/', config.api.baseUrl);

interface IOptions {
    displayOnlySurfaceModel: boolean; // Nur Flächenmodell darstellen
    view: 'face' | 'project';
    drillDown: boolean;
    hideInactive: boolean;
    ghostHidden: boolean;
}

interface ITableDataGroup {
    title: string;
    description?: string;
    color?: string;
    rows: ITableDataGroupRow[];
    sum: number;
    inactive?: boolean;
}

interface ITableDataGroupRow {
    name: string;
    value: number;
    inactive?: boolean;
    color?: string;
    componentIds?: string[];
    description?: string;
}

interface ITableGroup extends ITableDataGroup {
    options: IOptions;
    colors: IColor[];
}

interface IColor {
    [index: string]: string;
}

interface IFilterBar {
    onOutputColors: (color: ObjectsColoring[]) => void;
    onOutputOptions: (options: IOptions) => void;
    showcaseCode: string;
}

const numberRound = (num: number) => {
    return Math.round((num + Number.EPSILON) * 100) / 100;
};

const reformatNumber = (number: number, separator: string | undefined = ' ') => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
};

const TableGroup: React.FC<ITableGroup> = ({
    title,
    description,
    rows,
    sum,
    inactive,
    options,
    colors,
}) => {
    const classes = useStyles();

    const intl = useIntl();

    return (
        <Box className={clsx(classes.dataUsageBox, inactive && classes.tableRowInactive)}>
            <Box className={classes.tableGroupHeader}>
                <Box>
                    {colors[title] && (
                        <Box className={classes.color} style={{ backgroundColor: colors[title] }} />
                    )}
                    {options.view === 'face' &&
                        intl.formatMessage({
                            id: 'components.filterBar.data.key.' + title.toLowerCase(),
                        })}
                    {options.view === 'project' && (
                        <>
                            Lot {title} {description && <>({description})</>}
                        </>
                    )}
                </Box>
                <Box className={classes.nowrap}>
                    {reformatNumber(sum)} m<sup>2</sup>
                </Box>
            </Box>
            {options.drillDown && (
                <Box>
                    {rows.map((row, index) => {
                        return (
                            <Box
                                className={clsx(
                                    classes.tableRow,
                                    row.inactive && classes.tableRowInactive,
                                )}
                                key={index}
                            >
                                <Box pr={1}>
                                    {colors[row.name] && (
                                        <Box
                                            className={classes.color}
                                            style={{ backgroundColor: colors[row.name] }}
                                        />
                                    )}{' '}
                                    {options.view === 'face' && <>Lot</>} {row.name}{' '}
                                    {row.description && <>({row.description})</>}
                                </Box>
                                <Box className={classes.nowrap}>
                                    {reformatNumber(row.value)} m<sup>2</sup>
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
            )}
        </Box>
    );
};

const FilterBar: React.FC<IFilterBar> = ({ onOutputColors, onOutputOptions, showcaseCode }) => {
    const classes = useStyles();

    const [options, setOptions] = useState<IOptions>({
        displayOnlySurfaceModel: true,
        view: 'face',
        drillDown: true,
        hideInactive: false,
        ghostHidden: true,
    });
    const [sum, setSum] = useState<number>(0);
    const [filterShowcaseItems, setFilterShowcaseItems] = useState<string[]>([]);
    const [filterAreaUsages, setFilterAreaUsages] = useState<string[]>([]);
    const [showcaseItems, setShowcaseItems] = useState<any>([]);
    const [areaUsages, setAreaUsages] = useState<any>([]);
    const [tableDataGroups, setTableDataGroups] = useState<ITableDataGroup[]>([]);
    const [visibilityToggleOpen, setVisibilityToggleOpen] = useState<boolean>(true);
    const [colors, setColors] = useState<IColor[]>([]);

    // Load data
    useEffect(() => {
        if (showcaseCode) {
            api.get('ModelingShowcase/Detail/' + showcaseCode).then(
                (detailResponse: AxiosResponse) => {
                    setShowcaseItems(detailResponse.data.showcaseItems);
                },
            );

            api.get('ModelingShowcase/Detail/' + showcaseCode + '/AreaData').then(
                (areaResponse: AxiosResponse) => {
                    setAreaUsages(areaResponse.data.areaUsages);
                },
            );
        }
    }, []);

    // create color array
    useEffect(() => {
        const colorsToSet: IColor[] = [];
        areaUsages.map(area => {
            colorsToSet[area.name] = area.color;
        });
        setColors(colorsToSet);
    }, [areaUsages]);

    // Callback - Output colors
    useEffect(() => {
        let outputColorsData: ObjectsColoring[] = [];
        tableDataGroups.map(group => {
            if (!group.inactive) {
                group.rows.map(row => {
                    if (row.color && row.componentIds) {
                        if (!row.inactive) {
                            outputColorsData.push({
                                color: row.color,
                                ids: row.componentIds,
                            });
                        }
                    }
                });
            }
        });

        // merge arrays with same key color
        outputColorsData = _(outputColorsData)
            .groupBy('color')
            .map(g =>
                _.mergeWith({}, ...g, (obj, src) => (_.isArray(obj) ? obj.concat(src) : undefined)),
            )
            .value();

        onOutputColors(outputColorsData);
    }, [tableDataGroups]);

    // Callback - Output options
    useEffect(() => {
        onOutputOptions(options);
    }, [options]);

    // - prepare data for table
    // - apply filters for tableDataGroups
    useEffect(() => {
        // view:face
        // --------------------------------------
        let outputData: ITableDataGroup[] = [];
        let outputSumAll = 0;
        areaUsages.map(areaUsage => {
            const inactive = !filterAreaUsages.includes(areaUsage.name);

            // Prepare all rows for group
            const rows: ITableDataGroupRow[] = areaUsage.lands.map(land => {
                return {
                    name: land.name,
                    value: land.areaSum,
                    inactive: !filterShowcaseItems.includes(land.name),
                    componentIds: land.componentIds,
                    color: colors[areaUsage.name],
                };
            });

            // Get sum of all active rows in group
            let sumNumber = 0;
            rows.map(row => {
                // add sum only if row is active
                if (!row.inactive) {
                    sumNumber += row.value;
                    sumNumber = numberRound(sumNumber);
                }
            });

            // Filter - remove rows: if row is inactive and options.hideInactive
            const rowsFiltered: ITableDataGroupRow[] = rows.filter(row => {
                if (!(row.inactive && options.hideInactive)) {
                    return row;
                }
            });

            // Add group to outputData
            if (!(inactive && options.hideInactive)) {
                const newDataGroup: ITableDataGroup = {
                    title: areaUsage.name,
                    description: areaUsage.displayName,
                    rows: rowsFiltered,
                    sum: sumNumber,
                    inactive: inactive,
                };
                outputData.push(newDataGroup);

                // Add sum of group
                if (!inactive) {
                    outputSumAll += sumNumber;
                }
            }
        });

        // view:project
        // rearrange data from "view:face" and recalculate sum
        // --------------------------------------
        if (options.view === 'project') {
            // Get all Lots
            const lots: ITableDataGroup[] = [];
            outputData.map(group => {
                group.rows.map(row => {
                    // Prepare lot
                    if (!lots.find(x => x.title === row.name))
                        lots.push({
                            title: row.name,
                            rows: [],
                            inactive: row.inactive,
                            sum: 0,
                        });

                    // Add rows
                    lots.map(lot => {
                        if (lot.title === row.name) {
                            lot.rows.push({
                                name: group.title,
                                value: row.value,
                                inactive: group.inactive,
                                componentIds: row.componentIds,
                                color: colors[group.title],
                                description: group.description,
                            });
                        }
                    });
                });
            });

            // Sum
            lots.map(lot => {
                let lotSum = 0;
                lot.rows.map(row => {
                    if (!row.inactive) lotSum += row.value;
                });
                lot.sum = numberRound(lotSum);
            });

            outputData = lots;
        }

        setSum(numberRound(outputSumAll));
        setTableDataGroups(outputData);
    }, [filterShowcaseItems, filterAreaUsages, options, showcaseItems, areaUsages, colors]);

    // Init data for filters
    useEffect(() => {
        const arr = showcaseItems.map(showcaseItem => {
            return showcaseItem.code;
        });
        setFilterShowcaseItems(arr);
    }, [showcaseItems]);
    useEffect(() => {
        const arr = areaUsages.map(areaUsage => {
            return areaUsage.name;
        });
        setFilterAreaUsages(arr);
    }, [areaUsages]);

    const intl = useIntl();
    const transLabelSurfaces = intl.formatMessage({ ...messages.labelSurfaces });
    const transLabelSia = intl.formatMessage({ ...messages.labelSia });
    const transLabelFunctions = intl.formatMessage({ ...messages.labelFunctions });
    const transLabelDisplayOnlySurfaceModel = intl.formatMessage({
        ...messages.labelDisplayOnlySurfaceModel,
    });
    const transLabelDrilldown = intl.formatMessage({ ...messages.labelDrilldown });
    const transLabelHideInactive = intl.formatMessage({ ...messages.labelHideInactive });
    const transLabelGhostHidden = intl.formatMessage({ ...messages.labelGhostHidden });
    const transLabelFaceView = intl.formatMessage({ ...messages.labelFaceView });
    const transLabelProjectView = intl.formatMessage({ ...messages.labelProjectView });
    const transLabelTotal = intl.formatMessage({ ...messages.labelTotal });
    const transLabelFilter = intl.formatMessage({ ...messages.labelFilter });
    const transLabelSiaArea = intl.formatMessage({ ...messages.labelSiaArea });
    const transLabelLot = intl.formatMessage({ ...messages.labelLot });
    const transLabelUnclassified = intl.formatMessage({ ...messages.labelUnclassified });

    const handleSetDisplayOnlySurfaceModel = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOptions({ ...options, displayOnlySurfaceModel: event.target.checked });
    };

    const handleSetView = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).value as IOptions['view'];
        setOptions({ ...options, view: value });
    };

    const handleSetDrillDown = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOptions({ ...options, drillDown: event.target.checked });
    };

    const handleSetHideInactive = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOptions({ ...options, hideInactive: event.target.checked });
    };

    const handleSetGhostHidden = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOptions({ ...options, ghostHidden: event.target.checked });
    };

    const handleFilterSetShowcaseItem = (
        event: React.ChangeEvent<HTMLInputElement>,
        code: string,
    ) => {
        if (filterShowcaseItems.includes(code)) {
            const arr = [...filterShowcaseItems];
            arr.splice(filterShowcaseItems.indexOf(code), 1);
            setFilterShowcaseItems(arr);
        } else {
            setFilterShowcaseItems([...filterShowcaseItems, code]);
        }
    };

    const handleFilterSetAreaUsages = (
        event: React.ChangeEvent<HTMLInputElement>,
        name: string,
    ) => {
        if (filterAreaUsages.includes(name)) {
            const arr = [...filterAreaUsages];
            arr.splice(filterAreaUsages.indexOf(name), 1);
            setFilterAreaUsages(arr);
        } else {
            setFilterAreaUsages([...filterAreaUsages, name]);
        }
    };

    const handleVisibilityToggle = () => {
        setVisibilityToggleOpen(!visibilityToggleOpen);
    };

    return (
        <>
            <Box className={clsx(classes.root, visibilityToggleOpen && classes.rootOpen)}>
                <Box className={classes.tabs}>
                    <Box className={classes.tab}>{transLabelSia}</Box>
                    <Box className={clsx(classes.tab, classes.tabInactive)}>Gruner</Box>
                </Box>

                <Box
                    className={clsx(
                        classes.visibilityToggle,
                        visibilityToggleOpen && classes.visibilityToggleOpen,
                    )}
                    onClick={handleVisibilityToggle}
                >
                    <Icon name="arrow-from-left" size={20} />
                </Box>

                <Box className={classes.wrapper}>
                    <Box className={classes.mainTitle}>{transLabelSurfaces}</Box>

                    <Box className={classes.hr} />

                    <Box className={classes.sectionTitle}>{transLabelFunctions}</Box>

                    <FormControl className={classes.my0}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    className={classes.checkbox}
                                    onChange={handleSetDisplayOnlySurfaceModel}
                                    size="small"
                                    checked={options.displayOnlySurfaceModel}
                                />
                            }
                            className={classes.checkboxLabel}
                            label={transLabelDisplayOnlySurfaceModel}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    className={classes.checkbox}
                                    onChange={handleSetDrillDown}
                                    size="small"
                                    checked={options.drillDown}
                                />
                            }
                            className={classes.checkboxLabel}
                            label={transLabelDrilldown}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    className={classes.checkbox}
                                    onChange={handleSetHideInactive}
                                    size="small"
                                    checked={options.hideInactive}
                                />
                            }
                            className={classes.checkboxLabel}
                            label={transLabelHideInactive}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    className={classes.checkbox}
                                    onChange={handleSetGhostHidden}
                                    size="small"
                                    checked={options.ghostHidden}
                                />
                            }
                            className={classes.checkboxLabel}
                            label={transLabelGhostHidden}
                        />
                    </FormControl>

                    <FormControl className={classes.mb0}>
                        <RadioGroup
                            aria-labelledby="radio-buttons-group-face"
                            defaultValue="face"
                            name="radio-buttons-group"
                            onChange={handleSetView}
                        >
                            <FormControlLabel
                                value="face"
                                control={<Radio className={classes.radio} size="small" />}
                                className={classes.radioLabel}
                                label={transLabelFaceView}
                            />
                            <FormControlLabel
                                value="project"
                                control={<Radio className={classes.radio} size="small" />}
                                className={classes.radioLabel}
                                label={transLabelProjectView}
                            />
                        </RadioGroup>
                    </FormControl>

                    <Box className={classes.hr} />

                    <Box className={classes.sectionTitle}>{transLabelSia}</Box>

                    {tableDataGroups?.map((areaUsage, index) => {
                        if (areaUsage.sum === 0) return false; // hide if target don´t have any lands (rows)
                        return (
                            <TableGroup
                                key={index}
                                title={areaUsage.title}
                                description={areaUsage.description}
                                rows={areaUsage.rows}
                                options={options}
                                sum={areaUsage.sum}
                                inactive={areaUsage.inactive}
                                colors={colors}
                            />
                        );
                    })}

                    <Box className={classes.total}>
                        <Box>{transLabelTotal}</Box>
                        <Box className={classes.nowrap}>
                            {reformatNumber(sum)} m<sup>2</sup>
                        </Box>
                    </Box>

                    <Box className={classes.sectionTitle}>{transLabelFilter}</Box>

                    <Box className={classes.hr} />

                    <Box className={classes.filterTitle}>{transLabelLot}</Box>

                    <FormControl className={classes.my0}>
                        {showcaseItems.map((showcaseItem, index) => {
                            return (
                                <FormControlLabel
                                    key={index}
                                    control={
                                        <Checkbox
                                            className={classes.checkbox}
                                            onChange={event =>
                                                handleFilterSetShowcaseItem(
                                                    event,
                                                    showcaseItem.code,
                                                )
                                            }
                                            size="small"
                                            defaultChecked
                                        />
                                    }
                                    className={classes.checkboxLabel}
                                    label={`Lot ${showcaseItem.code}`}
                                />
                            );
                        })}
                    </FormControl>

                    <Box className={classes.hr} />

                    <Box className={classes.filterTitle}>{transLabelSiaArea}</Box>

                    <FormControl className={classes.my0}>
                        {areaUsages.map((areaUsage, index) => {
                            if (areaUsage.lands.length === 0) return false; // hide if target don´t have any lands (rows)
                            return (
                                <Box display={'flex'} alignItems={'center'} key={index}>
                                    <FormControlLabel
                                        key={index}
                                        control={
                                            <>
                                                <Checkbox
                                                    className={classes.checkbox}
                                                    onChange={event =>
                                                        handleFilterSetAreaUsages(
                                                            event,
                                                            areaUsage.name,
                                                        )
                                                    }
                                                    size="small"
                                                    defaultChecked
                                                />
                                                {colors[areaUsage.name] && (
                                                    <Box
                                                        className={classes.filterColor}
                                                        style={{
                                                            backgroundColor:
                                                                colors[areaUsage.name as string],
                                                        }}
                                                    />
                                                )}
                                            </>
                                        }
                                        className={clsx(classes.checkboxLabel, classes.filterValue)}
                                        label={intl.formatMessage({
                                            id:
                                                'components.filterBar.data.key.' +
                                                areaUsage.name.toLowerCase(),
                                        })}
                                    />
                                </Box>
                            );
                        })}

                        {/* todo - unclassified */}
                        <FormControlLabel
                            control={
                                <Checkbox
                                    className={classes.checkbox}
                                    onChange={event =>
                                        handleFilterSetAreaUsages(event, 'unclassified')
                                    }
                                    size="small"
                                />
                            }
                            className={classes.checkboxLabel}
                            label={transLabelUnclassified}
                        />
                    </FormControl>
                </Box>
            </Box>
        </>
    );
};

export default FilterBar;
