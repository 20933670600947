import { IonRouterOutlet } from '@ionic/react';
import { useKeycloak } from '@react-keycloak/web';
import Keycloak from 'keycloak-js';
import React from 'react';
import { Route } from 'react-router';

import { IIcon } from '../components/Icon/IconSet';
import { KeycloakRealmRole } from '../redux/keyclock/types';
import EsriPage from './EsriPage/EsriPage';
import IconsPage from './IconsPage/IconsPage';
import NotFoundPage from './NotFoundPage/NotFoundPage';

export interface AppPage {
    title: string;
    path: string;
    exact?: boolean;
    icon: IIcon;
    component?: React.FunctionComponent<any> | undefined | null;
    pages?: AppPage;
    wip?: boolean;
    inMenu?: boolean;
    inDashboard?: boolean;
    children?: AppPage[];
    requiredRole?: KeycloakRealmRole;
    disabled?: boolean;
}

export enum Path {
    ROOT = '/:modelID?',
    ICONS = '/icons',
}

export const appPages: AppPage[] = [
    {
        title: 'Dashboard',
        path: Path.ROOT,
        icon: 'chevron-left',
        exact: true,
        component: EsriPage,
    },
    {
        title: 'SIA 416 Area',
        path: '',
        icon: 'dashboard-platform',
        exact: true,
        component: null,
        inDashboard: true,
        disabled: true,
    },
    {
        title: 'Cost management',
        path: '',
        icon: 'dashboard-money-sheet',
        exact: true,
        component: null,
        inDashboard: true,
        disabled: true,
    },
    {
        title: 'Project comparison',
        path: '',
        icon: 'dashboard-platform-split',
        exact: true,
        component: null,
        inDashboard: true,
        disabled: true,
    },
    {
        title: 'Project management',
        path: '',
        icon: 'settings',
        exact: true,
        component: null,
        inDashboard: true,
        disabled: true,
    },
    {
        title: '(Scheduling)',
        path: '',
        icon: 'dashboard-gantt',
        exact: true,
        component: null,
        inDashboard: true,
        disabled: true,
    },
];

export const hasRouteAccess = (keycloak: Keycloak, appPage: AppPage) => {
    return !appPage.requiredRole || keycloak?.hasRealmRole(appPage.requiredRole);
};

const PageRouter: React.FC = () => {
    const { keycloak, initialized: keycloakInitialized } = useKeycloak();

    return (
        <IonRouterOutlet>
            {keycloakInitialized &&
                keycloak &&
                appPages
                    .filter(appPage => hasRouteAccess(keycloak, appPage))
                    .map((appPage, index) => (
                        <Route
                            key={index}
                            path={appPage.path}
                            exact={appPage.exact}
                            render={props => {
                                const RouteComponent = appPage.component;
                                return (
                                    RouteComponent && (
                                        <RouteComponent {...props} title={appPage.title} />
                                    )
                                );
                            }}
                        />
                    ))}
            <Route path={Path.ICONS} exact={true} component={IconsPage} />
            <Route component={NotFoundPage} />
        </IonRouterOutlet>
    );
};

export default PageRouter;
