import SceneView from '@arcgis/core/views/SceneView';
import Expand from '@arcgis/core/widgets/Expand';
import Widget from '@arcgis/core/widgets/Widget';
import { Card, CardContent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';

import { IToolDef } from './EsriViewer';

interface IEsriToolsProps {
    view: SceneView;
    tools: IToolDef[];
}

const EsriTools: React.FC<IEsriToolsProps> = ({ view, tools }) => {
    // todo: better handling tools, eg. add or remove single tool
    const [toolsAdded, setToolsAdded] = useState<boolean>(false);

    useEffect(() => {
        if (view && tools && !toolsAdded) {
            tools.forEach(tool => {
                let content: Node | string | Widget;
                if (React.isValidElement(tool.tool)) {
                    const toolElement = document.createElement('div');
                    const toolRoot = createRoot(toolElement);
                    toolRoot.render(
                        <Card>
                            <CardContent>{tool.tool}</CardContent>
                        </Card>,
                    );
                    content = toolElement;
                } else {
                    content = tool.tool as Node | string | Widget;
                }
                const expand = new Expand({
                    view: view,
                    content: content,
                    group: 'tools',
                    expandTooltip: tool.name,
                });

                view.ui.add(expand, {
                    position: 'top-right',
                    index: 1,
                });
            });

            setToolsAdded(true);
        }
    }, [view, tools]);

    return null;
};
export default EsriTools;
