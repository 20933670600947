import './style.css';

import Basemap from '@arcgis/core/Basemap';
import esriConfig from '@arcgis/core/config';
import FeatureLayerView from '@arcgis/core/views/layers/FeatureLayerView';
import SceneView from '@arcgis/core/views/SceneView';
import BasemapGallery from '@arcgis/core/widgets/BasemapGallery';
import Daylight from '@arcgis/core/widgets/Daylight';
import { Menu, MenuItem } from '@mui/material';
import React, { useEffect } from 'react';
import { generatePath, useParams } from 'react-router';
import { Link, useHistory } from 'react-router-dom';

import config from '../../config/config';
import { Path } from '../../pages/PageRouter';
import ModellingProgressDialog from '../ModelingProgressDialog/ModellingProgressDialog';
import EsriViewer, { ClickedPoint, Highlights, IToolDef } from './EsriViewer';
import FeatureFilter, { IFeatureFilter } from './FeatureFilter';
import thumbBhp from './Variants/bhp.png';
import thumbBp from './Variants/bp.png';
import thumbDp from './Variants/dp.png';
import thumbMax from './Variants/max.png';

const getObjectID = (point: any) => {
    const attrs = point.object.graphic.attributes;
    return attrs.OBJECTID ? attrs.OBJECTID : attrs.OBJECTID_1 ? attrs.OBJECTID_1 : attrs.ObjectId;
};

interface PageParams {
    modelID: string;
}

const applyFilter = (view: SceneView, filter: any) => {
    const layer = view.map.findLayerById(config.esri.layerId);
    view.whenLayerView(layer).then(layerView => {
        (layerView as FeatureLayerView).filter = filter;
    });
};

const filters: IFeatureFilter[] = [
    {
        code: 'max',
        name: 'Machbarkeitsstudie',
        description: 'EMWE Architekten AG',
        filter: sceneView =>
            applyFilter(sceneView, {
                where: `ioLabs_ID = 'MAX'`,
            }),
        thumbnail: thumbMax,
    },
    {
        code: 'bhp',
        name: 'BHP',
        description: 'Baumschlager Hutter Partners',
        filter: sceneView =>
            applyFilter(sceneView, {
                where: `ioLabs_ID = 'BHP'`,
            }),
        thumbnail: thumbBhp,
    },
    {
        code: 'bp',
        name: 'B+P',
        description: 'Burckhardt+Partner AG',
        filter: sceneView =>
            applyFilter(sceneView, {
                where: `ioLabs_ID = 'BP'`,
            }),
        thumbnail: thumbBp,
    },
    {
        code: 'dp',
        name: 'DP',
        description: 'Dachtler Partner AG',
        filter: sceneView =>
            applyFilter(sceneView, {
                where: `ioLabs_ID = 'DP'`,
            }),
        thumbnail: thumbDp,
    },
];

const getTools = (view: SceneView): IToolDef[] => {
    return [
        {
            name: 'Base map',
            code: 'basemap',
            icon: 'map',
            tool: new BasemapGallery({
                view: view,
                // activeBasemap: Basemap.fromId('arcgis-terrain'),
                source: [
                    Basemap.fromId('arcgis-terrain'),
                    Basemap.fromId('satellite'),
                    Basemap.fromId('streets-vector'),
                ],
            }),
        },
        {
            name: 'Daylight',
            code: 'daylight',
            icon: 'light_mode',
            tool: new Daylight({
                view: view,
            }),
        },
        {
            name: 'Variants',
            code: 'filter',
            icon: 'filter_alt',
            tool: <FeatureFilter view={view} filters={filters} />,
        },
        // {
        //     name: 'Shadow casting',
        //     code: 'shadowcast',
        //     icon: 'brightness_medium',
        //     tool: new ShadowCast({
        //         view: view,
        //     }),
        // },
    ];
};

const CustomEsriViewer: React.FC = () => {
    const [view, setView] = React.useState<SceneView>();
    const [highlights, setHighlights] = React.useState<Highlights>();
    const [clickedPoint, setClickedPoint] = React.useState<ClickedPoint | null>(null);

    const history = useHistory();
    const { modelID } = useParams<PageParams>();

    useEffect(() => {
        esriConfig.apiKey = config.esri.apiKey;
    }, []);

    useEffect(() => {
        if (view) {
            filters[0].filter(view); // apply first filter
        }
        return () => {
            setClickedPoint(null);
            setHighlights({});
        };
    }, [view]);

    const handleClose = () => {
        setClickedPoint(null);
        setHighlights({});
    };

    const handleClick = (point: ClickedPoint) => {
        if (point.object) {
            setClickedPoint(point);
            setHighlights({
                [point.object.graphic.layer.id]: [getObjectID(point)],
            });
        } else {
            setHighlights({});
            setClickedPoint(null);
        }
    };

    const handleShowProperties = () => {
        handleClose();
        if (clickedPoint) {
            view?.popup.open({
                location: clickedPoint.world,
                features: view.popup.features,
                fetchFeatures: true,
            });
        }
    };

    const handleViewLoaded = (loadedView: SceneView) => {
        setView(loadedView);
    };

    const handleCloseDetailDialog = () => {
        history.push(generatePath(Path.ROOT));
    };

    return (
        <>
            <EsriViewer
                webSceneId={config.esri.webSceneId}
                onClick={handleClick}
                viewLoaded={handleViewLoaded}
                disableAutoPopup={true}
                highlights={highlights}
                toolsGetter={getTools}
                baseMap={Basemap.fromId('arcgis-terrain')}
            />
            <Menu
                open={clickedPoint !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    clickedPoint !== null
                        ? { top: clickedPoint.screen.y, left: clickedPoint.screen.x }
                        : undefined
                }
            >
                <MenuItem
                    component={Link}
                    to={
                        clickedPoint
                            ? generatePath(Path.ROOT, {
                                  modelID: /*getObjectID(clickedPoint)*/ 'gruner-01',
                              })
                            : '#'
                    }
                    onClick={handleClose}
                >
                    Show model
                </MenuItem>
                <MenuItem onClick={handleShowProperties}>Show properties</MenuItem>
                <MenuItem disabled onClick={handleClose}>
                    Export
                </MenuItem>
            </Menu>
            {modelID && (
                <ModellingProgressDialog
                    isOpen={true}
                    showcaseCode={modelID}
                    onClose={handleCloseDetailDialog}
                />
            )}
        </>
    );
};
export default CustomEsriViewer;
