import { Alert, Box, Button, Fab, Tooltip, Typography } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import clsx from 'clsx';
import ForgeViewer from 'iolabs-react-forge-viewer';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { viewerToken } from '../../packages/Api/data/viewer/client';
import SiaVisualizer from '../../packages/SiaVisualizer/SiaVisualizer';
import { isJwtExpired } from '../../utils/Jwt';
import Icon from '../Icon/Icon';
import PushpinsInViewer from '../PushpinsInViewer/PusphinsInViewer';
import { Emea, ViewerState } from '../Viewer/types';
import messages from './messages';
import { IModelingItemProgress } from './ModelingProgressItem';
import PushpinPopover from './PushpinPopover';
import useStyles from './styles';

interface IModelingProgress {
    modelInfo: ViewerState;
    viewerState?: object;
    items?: IModelingItemProgress[];
    showcaseCode: string;
}

const ModelingProgress: React.FC<IModelingProgress> = ({
    modelInfo,
    viewerState,
    items,
    showcaseCode,
}) => {
    const classes = useStyles();

    const { keycloak } = useKeycloak();

    const [modelLoaded, setModelLoaded] = useState<boolean>(false);
    const [viewer, setViewer] = useState<Autodesk.Viewing.Viewer3D>();
    const [forgeToken, setForgeToken] = useState<string>();

    const [showPopovers, setShowPopovers] = useState<boolean>(true);
    const [showStatus, setShowStatus] = useState<boolean>(true);
    const [showArea, setShowArea] = useState<boolean>(true);

    // viewables
    const [viewable, setViewable] = useState<Autodesk.Viewing.BubbleNode>();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [viewables, setViewables] = useState<Autodesk.Viewing.BubbleNode[]>();

    // translations
    const intl = useIntl();
    const transShowPopovers = intl.formatMessage({ ...messages.showPopovers });
    const transHidePopovers = intl.formatMessage({ ...messages.hidePopovers });
    const transButtonStatus = intl.formatMessage({ ...messages.buttonStatus });
    const transButtonSurfaces = intl.formatMessage({ ...messages.buttonSurfaces });

    const handleTokenRequested = (onAccessToken: (token: string) => void) => {
        if (!forgeToken || isJwtExpired(forgeToken, 30)) {
            viewerToken(keycloak?.token as string).then(response => {
                setForgeToken(response);
                onAccessToken(response);
            });
        } else {
            onAccessToken(forgeToken);
        }
    };

    const handleDocumentLoaded = (
        doc: Autodesk.Viewing.Document,
        loadedViewables: Autodesk.Viewing.BubbleNode[],
    ) => {
        setViewable(loadedViewables[0]);
        setViewables(loadedViewables);
    };

    const handleForgeScriptLoaded = () => {};

    const handleModelError = error => {
        console.error('Error loading the model: ' + error);
    };

    const handleViewerLoaded = (laodedViewer: Autodesk.Viewing.Viewer3D) => {
        setViewer(laodedViewer);
    };

    const handleViewerError = error => {
        console.error('Error loading mobile viewer: ' + error);
    };

    const handleDocumentError = (loadedViewer: Autodesk.Viewing.Viewer3D, error: unknown) => {
        console.error('Error loading a document: ' + error);
    };

    const initState = (loadedViewer: Autodesk.Viewing.Viewer3D) => {
        if (viewerState) {
            loadedViewer.restoreState(viewerState);
        }
    };

    const handleModelLoaded = (loadedViewer: Autodesk.Viewing.Viewer3D) => {
        if (loadedViewer) {
            initState(loadedViewer);
        }

        setModelLoaded(true);
    };

    const onUnmount = () => {};

    const togglePopovers = () => {
        setShowPopovers(!showPopovers);
    };

    const toggleStatus = () => {
        setShowStatus(!showStatus);
    };

    const toggleArea = () => {
        setShowArea(!showArea);
    };

    return (
        <Box className={classes.viewerWrapper}>
            <Alert className={classes.demoAlert} severity="info">
                Dieses Modell ist eine Beispieldatei um die mögliche Funktionalität zu illustrieren.
            </Alert>
            {modelLoaded && showStatus && viewer && (
                <>
                    <PushpinsInViewer
                        viewer={viewer}
                        items={items}
                        renderPushpinContent={
                            !showPopovers
                                ? undefined
                                : (item, anchorElement, handleClose) => (
                                      <PushpinPopover
                                          anchorEl={anchorElement}
                                          item={item}
                                          handleClose={handleClose}
                                      />
                                  )
                        }
                        onCanvasClick={pushpin => {
                            console.log(
                                `${pushpin.coordinates.x}, ${pushpin.coordinates.y}, ${pushpin.coordinates.z}`,
                            );
                        }}
                    />
                    <Tooltip
                        title={showPopovers ? transHidePopovers : transShowPopovers}
                        className={classes.toggleButton}
                    >
                        <Fab color="primary" aria-label="toggle" onClick={togglePopovers}>
                            <Icon name={showPopovers ? 'eye-solid' : 'eye'} size={20} />
                        </Fab>
                    </Tooltip>
                </>
            )}

            {modelLoaded && showArea && viewer && (
                <SiaVisualizer viewer={viewer} showcaseCode={showcaseCode} />
            )}

            <ForgeViewer
                version="7.29"
                urn={modelInfo?.urn}
                api={modelInfo?.isEmea ? Emea.eu : Emea.default}
                view={viewable}
                query={{ type: 'geometry' }}
                onViewerError={handleViewerError}
                onTokenRequest={handleTokenRequested}
                onDocumentLoad={handleDocumentLoaded}
                onDocumentError={handleDocumentError}
                onModelLoad={handleModelLoaded}
                onModelError={handleModelError}
                onViewerLoad={handleViewerLoaded}
                onScriptLoaded={handleForgeScriptLoaded}
                onUnmount={onUnmount}
                forgeScriptAlreadyLoaded
                config={{
                    memory: {
                        limit: 1000,
                    },
                    profileSettings: {
                        lightPreset: 0,
                    },
                }}
            />

            <Box className={classes.navButtons}>
                <Button
                    onClick={toggleStatus}
                    className={clsx(classes.navButton, showStatus && classes.navButtonActive)}
                >
                    {transButtonStatus}
                </Button>
                <Button
                    onClick={toggleArea}
                    className={clsx(classes.navButton, showArea && classes.navButtonActive)}
                >
                    {transButtonSurfaces}
                </Button>
            </Box>
        </Box>
    );
};

export default ModelingProgress;
