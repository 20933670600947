import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        itemBox: {
            zIndex: 100,
            position: 'absolute',
            overflow: 'visible',
            transform: 'translate(-50%, -50%)',
            lineHeight: '0',
        },
        root: {
            width: '100%',
            height: '100%',
            position: 'absolute',
            zIndex: 10,
            pointerEvents: 'none',
        },
        viewerWrapper: {
            position: 'relative',
            zIndex: 0,
            height: '100%',
            width: '100%',
            '& .viewcubeWrapper': {
                top: '50px',
            },
        },
        toggleButton: {
            zIndex: 99,
            position: 'absolute',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
        },
        testScheme: {
            position: 'fixed',
            top: theme.spacing(1),
            right: '450px',
            zIndex: 9999,
            '& .MuiButton-root': {
                marginRight: theme.spacing(1),
            },
        },
        navButtons: {
            position: 'absolute',
            top: theme.spacing(1),
            right: theme.spacing(5),
            zIndex: 9999,
        },
        navButton: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            marginLeft: theme.spacing(1),
            color: theme.palette.common.white,
            background: theme.palette.grey['800'],
            '&:hover': {
                background: theme.palette.grey['600'],
            },
        },
        navButtonActive: {
            background: theme.palette.primary.main,
            color: theme.palette.common.white,
            '&:hover': {
                background: theme.palette.primary.dark,
            },
        },
        loading: {
            left: '50%',
            top: '50%',
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
        },
        demoAlert: {
            position: 'absolute',
            left: '50%',
            top: '15%',
            transform: 'translate(-50%, -50%)',
            zIndex: 99999,
        },
    }),
);

export default useStyles;
