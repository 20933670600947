import { createSelector } from 'reselect';

import { IFeedbackModuleState, IFeedbackState } from './reducer';

export function selectFeedbackState(state: IFeedbackModuleState): IFeedbackState {
    return state.feedbackState;
}

// loading
export const selectFeedbackLoading = createSelector(selectFeedbackState, state => state.loading);
