import { ApiClient } from '@iolabs/api-client';
import { AxiosResponse } from 'axios';

import config from '../../../../config/config';
import { IFeedbackSendRequestData, IFeedbackSendResponseData } from './types';

const feedbackApi = new ApiClient('/Feedback', config.api.baseUrl);

/**
 * Sends feedback to feedback system.
 * @param token
 * @param data
 */
export const feedbackSend = (
    token: string,
    data: IFeedbackSendRequestData,
): Promise<IFeedbackSendResponseData> => {
    ApiClient.setAuthorizationToken(token);
    return feedbackApi
        .post<IFeedbackSendResponseData>(`/Send`, data)
        .then((response: AxiosResponse<IFeedbackSendResponseData>) => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            return Promise.reject(error);
        });
};
